<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        {{cityTitle}}
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>
<div class="p-dialog-container" *ngIf="cityTemplate">
    <ng-container *ngTemplateOutlet="cityTemplate"></ng-container>
</div>


<ng-template #andrijevica>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of andrijevicaData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">051 243 214</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">051 230 920</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #bar>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of barData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">030 312 126</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">030 303 492, 303 493</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">030 313 980</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #berane>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                        SOS hotline for women and children victims of violence Berane
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    069 659 960
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of beraneData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="city-information-sos">
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">051 231 285</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">051 230 128, 234 966</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">050 233 344</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #bijelo_pole>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    SOS hotline for women and children victims of violence Bijelo Polje
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    050 433 660
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of bijeloPoljeData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">050 432 760</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">050 432 024</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Child and Family Support Center - Bijelo Polje</div>
                <div class="contact-number">050 433 651, 451 555</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">050 432 935</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #budva>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of budvaData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">033 451 238</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">033 452 887</div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #cetinje>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of cetinjeData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">041 231 103</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">041 231 890</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">041 231 933</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #danilovgrad>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of danilovgradData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">020 812 123</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">020 812 584</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">020 811 305</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #gusinje>

</ng-template>

<ng-template #herceg_novi>
    <div class="city-information">
        <div class="city-information-tables" *ngFor="let section of hercegNoviData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="city-information-sos">

        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">NVO Ksena Herceg Novi</div>
                <div class="contact-number">069 330 730</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">031 322 715</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">031 321 187, 322 293</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">031 324 111</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #kolasin>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of kolasinData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">020 865 153</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">020 865 645</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">020 865 820</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #kotor>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of kotorData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">032 325 737</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">032 322 622, 322 624, 304 740</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">032 325 566</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mojkovac>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of mojkovacData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">050 472 139</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">050 472 101</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #niksic>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    SOS hotline for women and children victims of violence Nikšić
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        National SOS line for victims of domestic violence
                    </div>
                    <div class="sos-list-item">
                        Safe accommodation
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/safe.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    080 111 111
                </div>
            </div>
            <div class="sos-item">
                <div class="sos-title">
                    Center for Roma Initiatives
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                    <div class="sos-list-item">
                        Assistance in regulating legal documentation
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/lawyer.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    040 246 369
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of niksicData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">040 213 069</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">040 215 207, 220 033</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">040 244 852, 244 862</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #petnjica>

</ng-template>

<ng-template #plav>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    SOS hotline for women and children victims of violence Plav
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                    <div class="sos-list-item">
                        Assistance in regulating legal documentation
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/lawyer.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    069 245 320
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of plavData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">051 251 136</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">051 255 075</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">051 251 061</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pljevlja>
    <div class="city-information">
        <div class="city-information-tables" *ngFor="let section of pljevljaData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">052 321 116</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">052 301 264</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">052 322 704, 321 051</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pluzine>

</ng-template>

<ng-template #podgorica>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    Safe women's house Podgorica
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Safe accommodation
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>

                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/safe.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>

                </div>
                <div class="sos-number">
                    069 013 352
                </div>
            </div>
            <div class="sos-item">
                <div class="sos-title">
                    SOS hotline for women and children victims of violence Podgorica
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Emergency accommodation
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/safe.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    020 232 254
                </div>
            </div>
            <div class="sos-item">
                <div class="sos-title">
                    Montenegrin women's lobby
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline for victims of sexual violence
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>

                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/safe.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    020 250 750
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of podgoricaData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Center</div>
                <div class="contact-number">020 224 804</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">020 230 563</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">020 481 200</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Institution of the Protector of Human Rights and Freedoms</div>
                <div class="contact-number">020 241 642</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #rozaje>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    NVO IKRE Rožaje
                </div>
                <div class="sos-list">

                    <div class="sos-list-item">
                        Psychological support and counseling
                    </div>
                    <div class="sos-list-item">
                        Free legal aid
                    </div>
                </div>
                <div class="sos-icons">

                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/lawyer.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    020 250 750
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of rozajeData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">051 271 395</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">051 271 009</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">051 271 595, 273 044</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tuzi>

</ng-template>

<ng-template #tivat>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of tivatData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">032 671 612</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">032 676 646</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ulcinj>
    <div class="city-information">
        <div class="city-information-sos">
            <div class="sos-item">
                <div class="sos-title">
                    SOS hotline for women and children victims of violence Ulcinj
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        SOS hotline
                    </div>
                    <div class="sos-list-item">
                        Emergency accommodation
                    </div>
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/safe.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    067 501 950
                </div>
            </div>
            <div class="sos-item">
                <div class="sos-title">
                    NVO Integritet
                </div>
                <div class="sos-list">
                    <div class="sos-list-item">
                        Accompanying a confidential person
                    </div>
                    <div class="sos-list-item">
                        Legal and psychological counseling
                    </div>
                </div>
                <div class="sos-icons">
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/guard-man.svg"></svg-icon>
                    </div>
                    <div class="sos-icons-item">
                        <svg-icon src="assets/sos/psychotherapy.svg"></svg-icon>
                    </div>
                </div>
                <div class="sos-number">
                    067 635 496
                </div>
            </div>
        </div>
        <div class="city-information-tables" *ngFor="let section of ulcinjData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">030 411 625</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">030 412 205</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">030 401 250</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #savnik>

</ng-template>

<ng-template #zabljak>
    <div class="city-information">
        <div class="city-information-sos">

        </div>
        <div class="city-information-tables" *ngFor="let section of zabljakData">
            <div *ngIf="hasData(section)">
                <h3 class="table-title">
                    {{section.title}}
                </h3>
                <div class="table-data">
                    <ng-container *ngFor="let item of section.table">
                        <ng-container *ngIf="item.count">
                            <div class="table-data-wrapper">
                                <div class="table-data-name">{{item.name}}</div>
                                <div class="table-data-value">
                                    {{item.count}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="important-contacts">
            <div>
                <h3 class="contacts-title">Important contacts</h3>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Security Department</div>
                <div class="contact-number">052 361 309</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Social service</div>
                <div class="contact-number">052 360 150</div>
            </div>
            <div class="contacts-wrapper">
                <div class="contact-name">Basic Court</div>
                <div class="contact-number">052 361 263</div>
            </div>
        </div>
    </div>
</ng-template>