<div class="p-dialog-header">
  <h4 class="p-dialog-header__title e-book-document-header">
    Women's Political Network
  </h4>
  <div class="p-dialog-header__close">
    <button class="btn-close" (click)="onClose()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
          stroke-width="1.50987" />
      </svg>
    </button>
  </div>
</div>
<div class="p-dialog-container">
  <div class="politics-network-main">
    <div class="politics-network-main-logo">
      <img src="/assets/zenska-politicka-mreza.png">
    </div>
    <div class="politics-network-main-contacts">
      <div class="other-link"><a href="https://www.facebook.com/zenskapolitickamreza" target="_blank">Women&#39;s
          Political Network</a></div>
      <div class="other-link"><a href="mailto:zenskapolitickamreza@yahoo.com">zenskapolitickamreza@yahoo.com</a></div>
    </div>
  </div>
  <div class="html-container">
    <p>The Women's Political Network (WPM) was established in November 2017, as a result of a long-term process of
      political empowerment of women in Montenegro under a program implemented by UNDP, in partnership with the Ministry
      of Human and Minority Rights, supported by the Delegation of the European Union to Montenegro.</p>
    <p>This coalition of women politicians from 18 parliamentary parties was launched with the aim of pointing out to
      the necessary policies that will ensure greater involvement of women in politics.</p>
    <p>The Women's Political Network draws on the knowledge of trainers in the field of gender equality in political
      parties and promotes a culture of political dialogue.</p>
    <p>Women from 18 parliamentary parties are gathered around topics and initiatives of common interest and consider
      them a priority for further joint political action.</p>
    <p>Thanks to the Women's Political Network initiative, the financing of political parties has been changed and it
      has been ensured that the political work and empowerment of women within parliamentary political parties
      continues. With the new Law on Financing of Political Entities and Election Campaigns, and thanks to the WPN norm,
      about half a million euros have already been allocated to women in parties, and from January 1, 2021,
      parliamentary parties with women in parliamentary clubs will receive additional funds, depending on the number of
      women. This is a significant achievement not only for Montenegro but also for the region. Also, the proposal of
      the election law includes the proposal of WPN to ensure 40% participation for the underrepresented gender, as well
      as one of the three places on the electoral lists. Further political dialogue on this topic is expected in the
      forthcoming period.</p>
    <p>In the field of economic empowerment of women, WPN is an initiative to support women's entrepreneurship at the
      local level, women entrepreneurs throughout Montenegro can use grants for their businesses. Determined to create
      systemic solutions and provide long-term support for the advancement of women, they managed to get 16 Montenegrin
      municipalities to recognize this measure as a permanent budget line.</p>
    <p>Finally, the Women's Political Network has paid special attention to the issue of violence against women in the
      past, and in that sense initiated the amendment of the Law on Protection from Domestic Violence and the Criminal
      Code, in order to ensure better protection of victims of violence.</p>
  </div>
</div>