<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        Report violence
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>
<div class="p-dialog-container">
    <div class="form-container">
        <div class="report-sos-phone">
            <div class="report-sos-phone-icon">
                <svg-icon src="assets/sos/sos-tel.svg"></svg-icon>
            </div>
            <div class="report-sos-phone-value">
                <div class="report-sos-phone-number">
                    080 111 111
                </div>
                <div class="report-sos-phone-label">
                    National SOS line for victims of domestic violence
                </div>
            </div>
        </div>
        <div class="email-hr">
            <svg-icon src="assets/icons/email.svg"></svg-icon>
        </div>
        <form [formGroup]="violenceForm" (ngSubmit)="onFormSubmit(captchaRef)">
            <div class="form-control control__name">
                <label for="date" class="input-title">Date and time
                    <span class="required-val"
                        *ngIf="!violenceForm.get('incident_happend').valid && violenceForm.get('incident_happend').touched">*</span>
                </label>
                <input type="datetime-local" id="name" class="control-input" formControlName="incident_happend">
            </div>
            <div class="form-control control__name">
                <label for="date" class="input-title">Telephone number
                    <span class="required-val"
                        *ngIf="!violenceForm.get('mobilePhone').valid && violenceForm.get('mobilePhone').touched">*</span>
                </label>
                <input type="tel" id="name" class="control-input" formControlName="mobilePhone" minlength="6"
                    maxlength="14">
            </div>
            <div class="form-control control__message">
                <label for="description" class="input-title">Write a message
                    <span class="required-val"
                        *ngIf="!violenceForm.get('description').valid && violenceForm.get('description').touched">*</span>
                </label>
                <textarea id="description" rows="5" cols="5" class="control-input"
                    formControlName="description"></textarea>
            </div>
            <div class="form-control control__checkbox">
                <label for="checkbox">
                    <input type="checkbox" name="checkbox" id="checkbox" (change)="acceptedPolicy = !acceptedPolicy">
                    <span class="label-text">I accept the terms of use and privacy policy</span>
                </label>
            </div>
            <re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="validCaptcha($event)"></re-captcha>
            <div class="send-btn-wrap">
                <button type="submit" class="send-btn" [disabled]="!acceptedPolicy || !violenceForm.valid">Send</button>
            </div>
        </form>
    </div>
</div>