import { NgModule } from '@angular/core';
import { ContentComponent } from './content.component';
import { RoutesSharedModule } from 'src/app/shared/routes-shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [ContentComponent],
  imports: [
    RouterModule,
    RoutesSharedModule
  ],
  exports: [ContentComponent]
})
export class ContentModule { }
