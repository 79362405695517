import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'politics-network-dialog',
  templateUrl: './politics-network-dialog.component.html',
})
export class PoliticsNetworkDialogComponent implements OnInit, OnDestroy {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    // this.documents = this.config.data.documents;
    // this.title = this.config.data.title;
  }

  ngOnInit(): void {}

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
