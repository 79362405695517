import { trigger, animate, style, query as q, transition, state } from '@angular/animations';

const query = (s, a, o = { optional: true }) => q(s, a, o);

export const TestTransition = trigger('TestTransition', [
  transition(':enter', [style({ display: 'none' }), animate('0.6s', style({ display: 'block' }))]),
  transition('* => *', [
    query(':self', [
      animate(
        '0.6s ease-in-out',
        style({
          opacity: 0,
          transform: 'translate3d(0, -10%, 0)',
          display: 'none',
        })
      ),
    ]),
  ]),
]);

export const NextTransition = trigger('NextTransition', [
  state('true', style({ transform: 'translateX(0)', opacity: 1 })),
  state('false', style({ transform: 'translateX(0)', opacity: 1 })),
  transition(
    'false <=> true',
    animate('0.6s ease-in-out', style({ transform: 'translateX(-10%)', opacity: 0 }))
  ),
]);

export const PrevTransition = trigger('PrevTransition', [
  state('true', style({ transform: 'translateX(0)', opacity: 1 })),
  state('false', style({ transform: 'translateX(0)', opacity: 1 })),
  transition(
    'false <=> true',
    animate('0.6s ease-in-out', style({ transform: 'translateX(-10%)', opacity: 0 }))
  ),
]);
