<div class="test-wrapper">

  <div [@TestTransition] class="intro" *ngIf="state === 'intro' && !finalResult">
    <div class="intro__title">
      {{ intro.title }}
    </div>
    <div class="intro__text">
      {{intro.description}}
    </div>

    <div class="intro__btn-container" *ngIf="!isMobile">
      <button class="intro__btn test__btn" (click)="onStartTest()">
        Start the test
      </button>
    </div>
  </div>

  <div [@TestTransition] class="intro__btn-container" *ngIf="isMobile && state === 'intro' && !finalResult">
    <button class="intro__btn test__btn" (click)="onStartTest()">
      Start the test
    </button>
  </div>


  <div [@TestTransition] class="question-container" *ngIf="state === 'question' && currentQuestion">
    <div [@NextTransition]="triggerNext" [@PrevTransition]="triggerPrev" class="question">
      {{currentQuestion.question}}
    </div>
    <div [@NextTransition]="triggerNext" (@NextTransition.done)="onNext(currentIndex,$event)"
      [@PrevTransition]="triggerPrev" (@PrevTransition.done)="onPrev(currentIndex,$event)" class="answers-container">
      <label [for]="i" class="answer" *ngFor="let answer of currentQuestion.answers; let i = index">{{answer.value}}
        <input type="radio" [id]="i" class="answer__radio-btn" name="question" [value]="i"
          (click)="onSelect(currentQuestion,answer)" [checked]="answer.checked">
        <div class="answer__custom-btn"></div>
      </label>
    </div>
  </div>


  <div [@TestTransition] class="intro" *ngIf="state === 'final-result'">
    <div class="intro__title" *ngIf="intro">
      {{ intro.title }}
    </div>
    <div class="test__avatar__mobile" *ngIf="isMobile">
      <svg-icon [src]="finalResult.image"></svg-icon>
    </div>
    <div class="intro__text final" [innerHTML]="finalResult.answer"></div>
  </div>


  <div [@TestTransition] class="slider-container" *ngIf="state === 'question' || state === 'final-result'">
    <div class="slider-btn-container">
      <button type="button" class="slider-btn" (click)="transitionPrev(currentQuestion)" [disabled]="currentIndex <= 0">
        <div class="btn-icon">
          <div class="btn-icon__prev"></div>
        </div>
        <span class="slider-btn__text__prev">Previous question</span>
      </button>
      <button [@TestTransition] type="button" class="slider-btn" *ngIf="state !== 'final-result'"
        (click)="transitionNext(currentQuestion)" [disabled]="!answered">
        <span class="slider-btn__text__next">Next question</span>
        <div class="btn-icon">
          <div class="btn-icon__next"></div>
        </div>
      </button>
      <button [@TestTransition] type="button" class="slider-btn test__btn" *ngIf="state === 'final-result'"
        (click)="repeatTest()">
        Try again
      </button>
    </div>
    <div class="slider">
      <div class="slider__bg"></div>
      <div class="slider__val" [ngStyle]="{width: sliderWidth +'%'}"></div>
    </div>
  </div>

</div>