import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-city-info-dialog',
  templateUrl: './city-info-dialog.component.html',
})
export class CityInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  cityTemplate: TemplateRef<any>;
  @ViewChildren(TemplateRef, { read: TemplateRef }) cityTemplates: QueryList<TemplateRef<any>>;

  cityId;
  cities: any = [
    {
      name: 'Andrijevica',
      id: 'andrijevica',
    },
    {
      name: 'Bar',
      id: 'bar',
    },
    {
      name: 'Berane',
      id: 'berane',
    },
    {
      name: 'Bijelo Polje',
      id: 'bijelo_pole',
    },
    {
      name: 'Budva',
      id: 'budva',
    },
    {
      name: 'Cetinje',
      id: 'cetinje',
    },
    {
      name: 'Danilovgrad',
      id: 'danilovgrad',
    },
    {
      name: 'Gusinje',
      id: 'gusinje',
    },
    {
      name: 'Herceg Novi',
      id: 'herceg_novi',
    },
    {
      name: 'Kolašin',
      id: 'kolasin',
    },
    {
      name: 'Kotor',
      id: 'kotor',
    },
    {
      name: 'Mojkovac',
      id: 'mojkovac',
    },
    {
      name: 'Nikšić',
      id: 'niksic',
    },
    {
      name: 'Petnjica',
      id: 'petnjica',
    },
    {
      name: 'Plav',
      id: 'plav',
    },
    {
      name: 'Pljevlja',
      id: 'pljevlja',
    },
    {
      name: 'Plužine',
      id: 'pluzine',
    },
    {
      name: 'Podgorica',
      id: 'podgorica',
    },
    {
      name: 'Rožaje',
      id: 'rozaje',
    },
    {
      name: 'Tuzi',
      id: 'tuzi',
    },
    {
      name: 'Tivat',
      id: 'tivat',
    },
    {
      name: 'Ulcinj',
      id: 'ulcinj',
    },
    {
      name: 'Šavnik',
      id: 'savnik',
    },
    {
      name: 'Žabljak',
      id: 'zabljak',
    },
  ];

  bijeloPoljeData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Mandatory treatment of addiction ',
          count: 4,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 4,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 90,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 46,
        },
        {
          name: 'Victim of Violence - Man',
          count: 43,
        },
        {
          name: 'Abuser -Man',
          count: 80,
        },
        {
          name: 'Abuser - Woman',
          count: 7,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 271,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 27,
        },
        {
          name: 'Prison Term',
          count: 11,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 4,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 44,
        },
        {
          name: 'Other',
          count: 2,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 20,
        },
        {
          name: 'Men victims of violence',
          count: 13,
        },
      ],
    },
  ];

  podgoricaData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 17,
        },
        {
          name: 'Restraining order',
          count: 34,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 26,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 3,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 12,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 563,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 375,
        },
        {
          name: 'Victim of Violence - Man',
          count: 167,
        },
        {
          name: 'Abuser -Man',
          count: 479,
        },
        {
          name: 'Abuser - Woman',
          count: 22,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 814,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 125,
        },
        {
          name: 'Prison Term',
          count: 63,
        },
        {
          name: 'Suspended Sentence',
          count: 150,
        },
        {
          name: 'Reprimand ',
          count: 32,
        },
        {
          name: 'Proceedings Dismissed',
          count: 23,
        },
        {
          name: 'Proceedings Dismissed',
          count: 5,
        },
        {
          name: 'Bill Dismissed',
          count: 6,
        },
        {
          name: 'Acquittal',
          count: 132,
        },
        {
          name: 'Other',
          count: 27,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 271,
        },
        {
          name: 'Men victims of violence',
          count: 130,
        },
      ],
    },
  ];

  cetinjeData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 13,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 10,
        },
        {
          name: 'Victim of Violence - Man',
          count: 5,
        },
        {
          name: 'Abuser -Man',
          count: 13,
        },
        {
          name: 'Abuser - Woman',
          count: 2,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 8,
        },
        {
          name: 'Prison Term',
          count: 0,
        },
        {
          name: 'Suspended Sentence',
          count: 2,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 3,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 15,
        },
        {
          name: 'Men victims of violence',
          count: 3,
        },
      ],
    },
  ];

  danilovgradData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 21,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 7,
        },
        {
          name: 'Victim of Violence - Man',
          count: 11,
        },
        {
          name: 'Abuser -Man',
          count: 19,
        },
        {
          name: 'Abuser - Woman',
          count: 3,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 15,
        },
        {
          name: 'Prison Term',
          count: 1,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 4,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 4,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  kolasinData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 17,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 16,
        },
        {
          name: 'Victim of Violence - Man',
          count: 10,
        },
        {
          name: 'Abuser -Man',
          count: 28,
        },
        {
          name: 'Abuser - Woman',
          count: 5,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 10,
        },
        {
          name: 'Prison Term',
          count: 0,
        },
        {
          name: 'Suspended Sentence',
          count: 2,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 4,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 11,
        },
        {
          name: 'Men victims of violence',
          count: 1,
        },
      ],
    },
  ];

  budvaData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 10,
        },
        {
          name: 'Restraining order',
          count: 29,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 16,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 1,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 71,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 39,
        },
        {
          name: 'Victim of Violence - Man',
          count: 16,
        },
        {
          name: 'Abuser -Man',
          count: 60,
        },
        {
          name: 'Abuser - Woman',
          count: 40,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 402,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 36,
        },
        {
          name: 'Prison Term',
          count: 4,
        },
        {
          name: 'Suspended Sentence',
          count: 5,
        },
        {
          name: 'Reprimand ',
          count: 16,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 8,
        },
        {
          name: 'Other',
          count: 1,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 75,
        },
        {
          name: 'Men victims of violence',
          count: 24,
        },
      ],
    },
  ];

  hercegNoviData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 104,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 69,
        },
        {
          name: 'Victim of Violence - Man',
          count: 41,
        },
        {
          name: 'Abuser -Man',
          count: 80,
        },
        {
          name: 'Abuser - Woman',
          count: 29,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 40,
        },
        {
          name: 'Prison Term',
          count: 3,
        },
        {
          name: 'Suspended Sentence',
          count: 30,
        },
        {
          name: 'Reprimand ',
          count: 11,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Bill Dismissed',
          count: 4,
        },
        {
          name: 'Acquittal',
          count: 12,
        },
        {
          name: 'Other',
          count: 1,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 73,
        },
        {
          name: 'Men victims of violence',
          count: 20,
        },
      ],
    },
  ];

  kotorData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 30,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 10,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 84,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 83,
        },
        {
          name: 'Victim of Violence - Man',
          count: 17,
        },
        {
          name: 'Abuser -Man',
          count: 90,
        },
        {
          name: 'Abuser - Woman',
          count: 6,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 24,
        },
        {
          name: 'Prison Term',
          count: 3,
        },
        {
          name: 'Suspended Sentence',
          count: 25,
        },
        {
          name: 'Reprimand ',
          count: 6,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Proceedings Dismissed',
          count: 3,
        },
        {
          name: 'Bill Dismissed',
          count: 2,
        },
        {
          name: 'Acquittal',
          count: 18,
        },
        {
          name: 'Other',
          count: 1,
        },
      ],
    },
    {
      title: 'Social work center - reported cases ',
      table: [
        {
          name: 'Women victims of violence',
          count: 32,
        },
        {
          name: 'Men victims of violence',
          count: 7,
        },
      ],
    },
  ];

  tivatData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 0,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 0,
        },
        {
          name: 'Victim of Violence - Man',
          count: 0,
        },
        {
          name: 'Abuser -Man',
          count: 0,
        },
        {
          name: 'Abuser - Woman',
          count: 0,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 0,
        },
        {
          name: 'Prison Term',
          count: 0,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 0,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 2,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
  ];

  barData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 4,
        },
        {
          name: 'Restraining order',
          count: 5,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 4,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 217,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 145,
        },
        {
          name: 'Victim of Violence - Man',
          count: 41,
        },
        {
          name: 'Abuser -Man',
          count: 195,
        },
        {
          name: 'Abuser - Woman',
          count: 16,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 43,
        },
        {
          name: 'Prison Term',
          count: 3,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 5,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 1,
        },
        {
          name: 'Acquittal',
          count: 40,
        },
        {
          name: 'Other',
          count: 2,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 15,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  ulcinjData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 48,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 33,
        },
        {
          name: 'Victim of Violence - Man',
          count: 20,
        },
        {
          name: 'Abuser -Man',
          count: 37,
        },
        {
          name: 'Abuser - Woman',
          count: 15,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 22,
        },
        {
          name: 'Prison Term',
          count: 2,
        },
        {
          name: 'Suspended Sentence',
          count: 1,
        },
        {
          name: 'Reprimand ',
          count: 2,
        },
        {
          name: 'Proceedings Dismissed',
          count: 5,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 11,
        },
        {
          name: 'Other',
          count: 4,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 3,
        },
        {
          name: 'Men victims of violence',
          count: 4,
        },
      ],
    },
  ];

  niksicData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 15,
        },
        {
          name: 'Restraining order',
          count: 18,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 9,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 0,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 0,
        },
        {
          name: 'Victim of Violence - Man',
          count: 0,
        },
        {
          name: 'Abuser -Man',
          count: 0,
        },
        {
          name: 'Abuser - Woman',
          count: 0,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 88,
        },
        {
          name: 'Prison Term',
          count: 19,
        },
        {
          name: 'Suspended Sentence',
          count: 21,
        },
        {
          name: 'Reprimand ',
          count: 22,
        },
        {
          name: 'Proceedings Dismissed',
          count: 9,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 53,
        },
        {
          name: 'Other',
          count: 3,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 98,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 66,
        },
      ],
    },
  ];

  mojkovacData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 1,
        },
        {
          name: 'Restraining order',
          count: 1,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 1,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 32,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 25,
        },
        {
          name: 'Victim of Violence - Man',
          count: 24,
        },
        {
          name: 'Abuser -Man',
          count: 44,
        },
        {
          name: 'Abuser - Woman',
          count: 8,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 9,
        },
        {
          name: 'Prison Term',
          count: 1,
        },
        {
          name: 'Suspended Sentence',
          count: 1,
        },
        {
          name: 'Reprimand ',
          count: 4,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 14,
        },
        {
          name: 'Other',
          count: 1,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 14,
        },
        {
          name: 'Men victims of violence',
          count: 13,
        },
      ],
    },
  ];

  beraneData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 38,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 32,
        },
        {
          name: 'Victim of Violence - Man',
          count: 13,
        },
        {
          name: 'Abuser -Man',
          count: 35,
        },
        {
          name: 'Abuser - Woman',
          count: 6,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 26,
        },
        {
          name: 'Prison Term',
          count: 2,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 3,
        },
        {
          name: 'Proceedings Dismissed',
          count: 1,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 6,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 65,
        },
        {
          name: 'Men victims of violence',
          count: 11,
        },
      ],
    },
  ];

  andrijevicaData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 0,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 0,
        },
        {
          name: 'Victim of Violence - Man',
          count: 0,
        },
        {
          name: 'Abuser -Man',
          count: 0,
        },
        {
          name: 'Abuser - Woman',
          count: 0,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 0,
        },
        {
          name: 'Prison Term',
          count: 0,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 0,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 1,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  plavData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 1,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 1,
        },
        {
          name: 'Victim of Violence - Man',
          count: 1,
        },
        {
          name: 'Abuser -Man',
          count: 1,
        },
        {
          name: 'Abuser - Woman',
          count: 0,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 0,
        },
        {
          name: 'Prison Term',
          count: 1,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 0,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 2,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  rozajeData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 1,
        },
        {
          name: 'Restraining order',
          count: 1,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 21,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 1,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 29,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 18,
        },
        {
          name: 'Victim of Violence - Man',
          count: 9,
        },
        {
          name: 'Abuser -Man',
          count: 26,
        },
        {
          name: 'Abuser - Woman',
          count: 4,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 23,
        },
        {
          name: 'Prison Term',
          count: 1,
        },
        {
          name: 'Suspended Sentence',
          count: 1,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 0,
        },
        {
          name: 'Other',
          count: 2,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 8,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  pljevljaData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 47,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 36,
        },
        {
          name: 'Victim of Violence - Man',
          count: 9,
        },
        {
          name: 'Abuser -Man',
          count: 45,
        },
        {
          name: 'Abuser - Woman',
          count: 1,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 30,
        },
        {
          name: 'Prison Term',
          count: 7,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 2,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 6,
        },
        {
          name: 'Other',
          count: 2,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 40,
        },
        {
          name: 'Men victims of violence',
          count: 11,
        },
      ],
    },
  ];

  zabljakData = [
    {
      title: 'Protective measures as proposed by police officers:',
      table: [
        {
          name: 'Removal from residence',
          count: 0,
        },
        {
          name: 'Restraining order',
          count: 0,
        },
        {
          name: 'Prohibition of Harassment and Stalking ',
          count: 0,
        },
        {
          name: 'Mandatory treatment of addiction ',
          count: 0,
        },
        {
          name: 'Mandatory psychosocial treatment',
          count: 0,
        },
      ],
    },
    {
      title: 'Misdemeanour court',
      table: [
        {
          name: 'Number of completed cases ',
          count: 17,
        },
        {
          name: 'Victim of Violence -Woman',
          count: 12,
        },
        {
          name: 'Victim of Violence - Man',
          count: 4,
        },
        {
          name: 'Abuser -Man',
          count: 24,
        },
        {
          name: 'Abuser - Woman',
          count: 0,
        },
        {
          name: 'Finished in Misdemeanor Court',
          count: 0,
        },
      ],
    },
    {
      title: 'The above cases ended as described below',
      table: [
        {
          name: 'Money fine',
          count: 8,
        },
        {
          name: 'Prison Term',
          count: 0,
        },
        {
          name: 'Suspended Sentence',
          count: 0,
        },
        {
          name: 'Reprimand ',
          count: 4,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Proceedings Dismissed',
          count: 0,
        },
        {
          name: 'Bill Dismissed',
          count: 0,
        },
        {
          name: 'Acquittal',
          count: 5,
        },
        {
          name: 'Other',
          count: 0,
        },
      ],
    },
    {
      title: 'Social work center - reported cases',
      table: [
        {
          name: 'Women victims of violence',
          count: 0,
        },
        {
          name: 'Men victims of violence',
          count: 0,
        },
      ],
    },
  ];

  cityTitle;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cityId = this.config.data.cityId;
    this.cityTitle = this.getCityTitle(this.config.data.cityId);
  }

  getCityTitle(cityId) {
    return this.cities.find((city) => city.id === cityId).name;
  }

  hasData(section) {
    return section.table.find((item) => item.count > 0);
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }

  ngAfterViewInit() {
    const template = this.getTemplate(this.cityId);
    this.cityTemplate = template;
    this.cd.detectChanges();
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.cityTemplates
      ? this.cityTemplates.find((template: any) => {
          if (
            template._declarationTContainer.localNames &&
            template._declarationTContainer.localNames.length
          ) {
            return template._declarationTContainer.localNames[0] === this.cityId;
          }
        })
      : null;
  }
}
