import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from '../angular-svg-icon/angular-svg-icon.module';
import { SvgIconComponent } from '../angular-svg-icon/svg-icon.component';
import { TestComponent } from './test.component';

@NgModule({
  declarations: [TestComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [TestComponent],
})
export class TestModule {}
