import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
} from '@angular/animations';
import {
  Component,
  Directive,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CalendarComponent } from 'src/app/shared/calendar-dialog/calendar-dialog.component';
import { ContactComponent } from 'src/app/shared/contact-dialog/contact-dialog.component';
import { GetTrainedComponent } from 'src/app/shared/get-trained-dialog/get-trained-dialog.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Directive({
  selector: '[ifChanges]',
})
export class IfChangesDirective {
  private currentValue: any;
  private hasView = false;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  @Input() set ifChanges(val: any) {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (val !== this.currentValue) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.currentValue = val;
    }
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
  animations: [
    trigger('headerTransition', [
      state('void', style({ transform: 'translate3d(100%, 0, 0)', opacity: 0 })),
      state('*', style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
      transition('void => *', [animate('0.5s 0.5s ease-out')]),
    ]),
    trigger('mobileMenuTransition', [
      state('void', style({ transform: 'translateX(100%)', opacity: 0 })),
      state('*', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('void => *', [animate('0.5s 0.5s ease-out')]),
      transition('* => void', [animate('0.5s ease-in')]),
    ]),
    trigger('changeTrigger', [
      state('void', style({ transform: 'translateX(-100%)', opacity: 0 })),
      state('*', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('void => *', [animate('0.5s 0.5s ease-out')]),
      transition('* => void', [animate('0.5s ease-in')]),
    ]),
    trigger('enterAnimation', [
      state('void', style({ width: '0px', opacity: 0 })),
      state('*', style({ width: '3.6vw', opacity: 1 })),
      transition('void => *', [animate('0.5s 0.5s ease-out')]),
      transition('* => void', [animate('0.5s ease-in')]),
    ]),
    trigger('logoAnimation', [
      state('void', style({ transform: 'translateX(-100%)', opacity: 0 })),
      state('*', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('void => *', [animate('0.5s 0.5s ease-out')]),
      transition('* => void', [animate('0.5s ease-in')]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  title;
  calendarRef: DynamicDialogRef;
  contactRef: DynamicDialogRef;
  getTrainedRef: DynamicDialogRef;
  showSidebarMobile = false;

  @ViewChild('quotesComponent')
  public quotesComponent: any;
  @ViewChild('quotesProgress')
  public quotesProgress: any;
  tick;
  isPause = false;
  percentTime;
  time = 10;

  quotes = [];

  quotesSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    // adaptiveHeight: true,
  };

  homeQuotes = [
    'The first woman driver in the Balkans was <strong>Ksenija Petrovic Njegos</strong> (1881-1960), the Montenegrin princess.She drove a Fiat 1100, a gift from her sister Jelena.',
    'The first woman doctor in Montenegro was <strong>Divna Vekovic<strong>. She was born in Luzac, outside Berane in 1886. She studied Medicine at Sorbonne University, and was the first to translate the Mountain Wreath into French in 1916.',
    'Teacher <strong>Jelena Vickovic</strong> founded the first private all-girls school in Cetinje in 1871.',
    'The first woman pilot in Montenegro was <strong>Olga Soskic Milovic</strong>. She was born in 1935, originally from Berane. Her first take off was in a glider, from Berane Aero Club in 1950’s.',
    `<strong>Jelena Cetkovic</strong> (1916 – 1943) took part in the Liberation War and is a Yugoslavia's national hero.`,
    '<strong>Draginja Vusovic</strong> was one of the first woman political activists. Born in 1917 in Brsno, she took part in the National Liberation War and was decorated with the Partisan Commemorative Medal in 1941.',
    'The first woman painter in Montenegro was <strong>Ksenija Vujović Tosic.</strong> Born in Trieste in 1930, she worked as a painter and decorator in Lovcen Film, but also as a teacher in Ljubljana and Podgorica.',
    'The first woman novelist in Montenegro was <strong>Milka Bajic Poderegin.</strong> She was born in Pljevlja in 1904, and graduated from Belgrade Faculty of Arts, World and National Literatures, in 1928.',
    '<strong>Ksenija Cicvaric<strong> (1929 - 1997) was a singer of Montenegrin folk songs. Her singing career took off in 1960s when she applied for an open competition to work as a singer for Radio Titograd.',
    `The first woman architect in Montenegro was <strong>Svetlana Kana Radevic</strong>. Born in 1937, she graduated from the Faculty of Architecture in Belgrade, and earned her master's degree from Pennsylvania University.`,
    'The first woman fighter pilot was <strong>Sabiha Gökçen</strong>. She was born in 1913, originally from Lozna, outside Bijelo Polje. UN Air Forces included her as the only woman among the twenty best pilots in aviation history.',
    '<strong>Jelisaveta M. Popovic</strong> is the first woman composer in the territory of Montenegro.',
    '<strong>Tamara Pavićevic</strong> is the first woman superintendent in the Montenegrin police – Division for Prevention and Police Work.',
    'The first graduate harpist in Montenegro is <strong>Milica Kankaras.</strong> At the age of 17, she enrolled in the Harp Department of Belgrade Faculty of Music Art.',
    '<strong>Ivana Canovic</strong> is the first opera singer to have performed on the Hollywood Walk of Fame. She studied at the Royal Academy in London.',
    'Lieutenant-colonel <strong>Kristina Bacic</strong> is the first and only woman pilot in the Montenegrin Airforce for now. She finished a military academy in Greece.',
    '<strong>Tamara Djurdjevac</strong> is the first and only woman to work in the Police Counter-Terrorism Unit. She was born in Niksic and was the national judo champion.',
    'From 1921 until today, the number of women in Montenegro has always been higher than the number of men.',
  ];

  violenceQuotes = [
    'During the COVID-19 pandemic, the largest number of reports of violence was related to psychological and physical violence, intimidation, belittling, threats of eviction.',
    'In the first four months of the pandemic in Montenegro, the number of cases of violence increased by 30%.',
    'Two thirds of citizens believe that the problem of domestic violence is not given enough attention in Montenegro.',
    '47% of citizens would not report violence because it is a private matter, and they did not want to interfere.',
    'Factors that prevent women from reporting violence are fear of bullies, shame, distrust of institutions and legal measures.',
    `The most common assistance of women's NGOs to victims of violence are: interviews with the victim (51%), counseling (15%) direct prevention of violence (12%), psychosocial assistance, legal counseling, provision of accommodation and confidential services.`,
    'The fight against violence against women begins with your belief in the victim.',
    'Only every tenth citizen understands that the basis of violence is an imbalance of power between women and men, traditional and patriarchal relations.',
    'One in five citizens believes that movement control or communication control is not violence.',
    'Phrases like “she asked for it” or “boys will be boys” justify bullies by nurturing a “culture of violence”.',
    `Campaign “16. days of activism against violence against women ", was established in 1991 at the initiative of the Institute for Global Women's Leadership when 6,000 organizations from 187 countries gathered.`,
    `The “16 Days of Activism Against Violence Against Women” campaign runs from November 25, International Day for the Elimination of Violence against Women, to December 10, when International Human Rights Day is celebrated.`,
    `"Paint the world orange" is an international anti-violence slogan and each year the campaign has a special theme.`,
  ];

  politicsQuotes = [
    'No sanction has ever been imposed for non-compliance with the Law on Gender Equality since its enactment until today.',
    '54% of Montenegrin citizens do not know any woman politician in the world about whom they have a very favorable opinion.',
    'Over half of the citizens believe that men in Montenegro have greater rights.',
    'Most citizens find it easier for men to advance in politics.',
    '70% of women do not discuss political topics with their friends.',
    `Citizens believe that a woman's role is predominantly related to the family.`,
    'More than half of citizens believe that men are more capable managers and political leaders, and that they should take leadership positions.',
    'Montenegro had its first candidate for President of Montenegro (<strong>Draginja Vuksanovic</strong>) only in 2018 or 77 years after the right to vote for women was confirmed in 1941 by the Supreme Headquarters of the National Liberation War.',
    'The first woman to head the Ministry of Defense in independent Montenegro was <strong>Milica Pejanovic Djurisic</strong> in 2012.',
    '57% of citizens believe that gender equality is impossible due to biological differences between men and women.',
    'Half of the employees in the institutions believe that feminism and the feminist movement have done more harm to women than they have contributed to improving their position.',
    '64% of employees in Montenegrin institutions believe that the issue of gender equality is unjustifiably forced.',
    'The functions of the President of the State and the Prime Minister are still predominantly considered male.',
    `None of the current political parties is seen as committed to women's issues.`,
    'If we continue at this pace, it will take us 95 years to close the gender gap in politics.',
  ];

  economyQuotes = [
    'Citizens believe that women are more often discriminated against in the labor market.',
    'Women make up 44% of the working population.',
    'More than half of the citizens think that it is best for a woman to dedicate herself to her family and a man to earn money.',
    'In the first three months of the pandemic, women earned € 122.3 million in cash equivalent of the value of unpaid care and domestic work.',
    `Montenegro does not have accurate statistics on women's entrepreneurship.`,
    'Women contribute to the GDP of Montenegro with about 2.038 billion euros or 44% of GDP (2018).',
    'Only 30% of women work in the highest paid jobs.',
    'Institutions generally do not keep gender-disaggregated statistics, and do not integrate gender aspects into policies, although they are legally obliged.',
    'GDP per capita is € 6,496 for women and € 8,571 for men.',
    'For a gender pay gap of 16%, women could buy an apartment of at least 31,000 euros.',
    'Women will spend at least 8 years of their lives in household chores.',
    'Rural women will work in unpaid jobs up to 15 years.',
    'Only 20% of women are in management positions in companies.',
    'If we continue at this pace, it will take us  257 years to close the economic gap.',
  ];

  sliderConfig = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    prevArrow: "<img class='a-left control-c' src='/assets/slider-prev.svg'>",
    nextArrow: "<img class='a-right control-c' src='/assets/slider-next.svg'>",
    appendArrows: '.photo-slider__arrows',
    lazyLoad: 'ondemand',
  };

  photos: any = [];

  homePhotos = [
    {
      src: 'assets/photos/homepage/src/1.jpg',
      thumb: 'assets/photos/homepage/thumbs/1.jpg',
    },
    {
      src: 'assets/photos/homepage/src/2.jpg',
      thumb: 'assets/photos/homepage/thumbs/2.jpg',
    },
    {
      src: 'assets/photos/homepage/src/3.jpg',
      thumb: 'assets/photos/homepage/thumbs/3.jpg',
    },
    {
      src: 'assets/photos/homepage/src/4.jpg',
      thumb: 'assets/photos/homepage/thumbs/4.jpg',
    },
    {
      src: 'assets/photos/homepage/src/5.jpg',
      thumb: 'assets/photos/homepage/thumbs/5.jpg',
    },
    {
      src: 'assets/photos/homepage/src/6.jpg',
      thumb: 'assets/photos/homepage/thumbs/6.jpg',
    },
    {
      src: 'assets/photos/homepage/src/7.jpg',
      thumb: 'assets/photos/homepage/thumbs/7.jpg',
    },
  ];

  violencePhotos = [
    {
      src: 'assets/photos/violence/src/1.jpg',
      thumb: 'assets/photos/violence/thumbs/1.jpg',
    },
    {
      src: 'assets/photos/violence/src/2.jpg',
      thumb: 'assets/photos/violence/thumbs/2.jpg',
    },
    {
      src: 'assets/photos/violence/src/3.jpg',
      thumb: 'assets/photos/violence/thumbs/3.jpg',
    },
    {
      src: 'assets/photos/violence/src/4.jpg',
      thumb: 'assets/photos/violence/thumbs/4.jpg',
    },
    {
      src: 'assets/photos/violence/src/5.jpg',
      thumb: 'assets/photos/violence/thumbs/5.jpg',
    },
    {
      src: 'assets/photos/violence/src/6.jpg',
      thumb: 'assets/photos/violence/thumbs/6.jpg',
    },
    {
      src: 'assets/photos/violence/src/7.jpg',
      thumb: 'assets/photos/violence/thumbs/7.jpg',
    },
    {
      src: 'assets/photos/violence/src/8.jpg',
      thumb: 'assets/photos/violence/thumbs/8.jpg',
    },
    {
      src: 'assets/photos/violence/src/9.jpg',
      thumb: 'assets/photos/violence/thumbs/9.jpg',
    },
    {
      src: 'assets/photos/violence/src/10.jpg',
      thumb: 'assets/photos/violence/thumbs/10.jpg',
    },
    {
      src: 'assets/photos/violence/src/11.jpg',
      thumb: 'assets/photos/violence/thumbs/11.jpg',
    },
    {
      src: 'assets/photos/violence/src/12.jpg',
      thumb: 'assets/photos/violence/thumbs/12.jpg',
    },
    {
      src: 'assets/photos/violence/src/13.jpg',
      thumb: 'assets/photos/violence/thumbs/13.jpg',
    },
    {
      src: 'assets/photos/violence/src/14.jpg',
      thumb: 'assets/photos/violence/thumbs/14.jpg',
    },
    {
      src: 'assets/photos/violence/src/15.jpg',
      thumb: 'assets/photos/violence/thumbs/15.jpg',
    },
    {
      src: 'assets/photos/violence/src/16.jpg',
      thumb: 'assets/photos/violence/thumbs/16.jpg',
    },
    {
      src: 'assets/photos/violence/src/17.jpg',
      thumb: 'assets/photos/violence/thumbs/17.jpg',
    },
    {
      src: 'assets/photos/violence/src/18.jpg',
      thumb: 'assets/photos/violence/thumbs/18.jpg',
    },
    {
      src: 'assets/photos/violence/src/19.jpg',
      thumb: 'assets/photos/violence/thumbs/19.jpg',
    },
    {
      src: 'assets/photos/violence/src/20.jpg',
      thumb: 'assets/photos/violence/thumbs/20.jpg',
    },
    {
      src: 'assets/photos/violence/src/21.jpg',
      thumb: 'assets/photos/violence/thumbs/21.jpg',
    },
  ];

  politicsPhotos = [
    {
      src: 'assets/photos/politics/src/00.jpg',
      thumb: 'assets/photos/politics/thumbs/00.jpg',
    },
    {
      src: 'assets/photos/politics/src/0.jpg',
      thumb: 'assets/photos/politics/thumbs/0.jpg',
    },
    {
      src: 'assets/photos/politics/src/1.jpg',
      thumb: 'assets/photos/politics/thumbs/1.jpg',
    },
    {
      src: 'assets/photos/politics/src/2.jpg',
      thumb: 'assets/photos/politics/thumbs/2.jpg',
    },
    {
      src: 'assets/photos/politics/src/3.jpg',
      thumb: 'assets/photos/politics/thumbs/3.jpg',
    },
    {
      src: 'assets/photos/politics/src/4.jpg',
      thumb: 'assets/photos/politics/thumbs/4.jpg',
    },
    {
      src: 'assets/photos/politics/src/5.jpg',
      thumb: 'assets/photos/politics/thumbs/5.jpg',
    },
    {
      src: 'assets/photos/politics/src/6.jpg',
      thumb: 'assets/photos/politics/thumbs/6.jpg',
    },
    {
      src: 'assets/photos/politics/src/7.jpg',
      thumb: 'assets/photos/politics/thumbs/7.jpg',
    },
    {
      src: 'assets/photos/politics/src/8.jpg',
      thumb: 'assets/photos/politics/thumbs/8.jpg',
    },
    {
      src: 'assets/photos/politics/src/9.jpg',
      thumb: 'assets/photos/politics/thumbs/9.jpg',
    },
    {
      src: 'assets/photos/politics/src/10.jpg',
      thumb: 'assets/photos/politics/thumbs/10.jpg',
    },
    {
      src: 'assets/photos/politics/src/11.jpg',
      thumb: 'assets/photos/politics/thumbs/11.jpg',
    },
    {
      src: 'assets/photos/politics/src/12.jpg',
      thumb: 'assets/photos/politics/thumbs/12.jpg',
    },
    {
      src: 'assets/photos/politics/src/13.jpg',
      thumb: 'assets/photos/politics/thumbs/13.jpg',
    },
    {
      src: 'assets/photos/politics/src/14.jpg',
      thumb: 'assets/photos/politics/thumbs/14.jpg',
    },
    {
      src: 'assets/photos/politics/src/15.jpg',
      thumb: 'assets/photos/politics/thumbs/15.jpg',
    },
    {
      src: 'assets/photos/politics/src/16.jpg',
      thumb: 'assets/photos/politics/thumbs/16.jpg',
    },
  ];

  economyPhotos = [
    {
      src: 'assets/photos/economy/src/1.jpg',
      thumb: 'assets/photos/economy/thumbs/1.jpg',
    },
    {
      src: 'assets/photos/economy/src/2.jpg',
      thumb: 'assets/photos/economy/thumbs/2.jpg',
    },
    {
      src: 'assets/photos/economy/src/3.jpg',
      thumb: 'assets/photos/economy/thumbs/3.jpg',
    },
    {
      src: 'assets/photos/economy/src/4.jpg',
      thumb: 'assets/photos/economy/thumbs/4.jpg',
    },
    {
      src: 'assets/photos/economy/src/5.jpg',
      thumb: 'assets/photos/economy/thumbs/5.jpg',
    },
    {
      src: 'assets/photos/economy/src/6.jpg',
      thumb: 'assets/photos/economy/thumbs/6.jpg',
    },
    {
      src: 'assets/photos/economy/src/7.jpg',
      thumb: 'assets/photos/economy/thumbs/7.jpg',
    },
  ];

  activePhotos;

  constructor(
    router: Router,
    public dialogService: DialogService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _lightbox: Lightbox
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // set viewport for prikazi mi
        if (router.url.includes('show-me')) {
          var vp = this._document.getElementById('viewport');
          vp.setAttribute('content', 'width=device-width');
        } else {
          var vp = this._document.getElementById('viewport');
          vp.setAttribute(
            'content',
            'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
          );
        }
        // close dialogs on route change
        if (this.calendarRef) {
          this.calendarRef.close();
        }
        if (this.contactRef) {
          this.contactRef.close();
        }

        if (this.getTrainedRef) {
          this.getTrainedRef.close();
        }

        // reset body clases
        this._document.body.classList.remove(`violence-page`);
        this._document.body.classList.remove(`politics-page`);
        this._document.body.classList.remove(`economy-page`);
        this._document.body.classList.remove(`avatars-page`);
        this._document.body.classList.remove(`home-page`);

        // per page customization
        if (router.url.includes('gender-based-violence')) {
          this._document.body.classList.add(`violence-page`);
          this.title = 'Gender-based violence';
          if (this.activePhotos !== 'violence') {
            this.renderPhotos(this.violencePhotos);
            this.renderQuotes(this.violenceQuotes);
          }
          this.activePhotos = 'violence';
        } else if (router.url.includes('politics')) {
          this._document.body.classList.add(`politics-page`);
          this.title = 'Politics';
          if (this.activePhotos !== 'politics') {
            this.renderPhotos(this.politicsPhotos);
            this.renderQuotes(this.politicsQuotes);
          }
          this.activePhotos = 'politics';
        } else if (router.url.includes('economy')) {
          this._document.body.classList.add(`economy-page`);
          this.title = 'Economy';
          if (this.activePhotos !== 'economy') {
            this.renderPhotos(this.economyPhotos);
            this.renderQuotes(this.economyQuotes);
          }
          this.activePhotos = 'economy';
        } else if (router.url.includes('choose-your-guides')) {
          this._document.body.classList.add(`avatars-page`);
          this.title = 'Choose your guides';
          if (this.activePhotos !== 'home') {
            this.renderPhotos(this.homePhotos);
            this.renderQuotes(this.homeQuotes);
          }
          this.activePhotos = 'home';
        } else {
          this._document.body.classList.add(`home-page`);
          this.title = '';
          if (this.activePhotos !== 'home') {
            this.renderPhotos(this.homePhotos);
            this.renderQuotes(this.homeQuotes);
          }
          this.activePhotos = 'home';
        }
      }
    });
  }

  openCalendar() {
    this.calendarRef = this.dialogService.open(CalendarComponent, {
      showHeader: false,
      closeOnEscape: true,
      closable: true,
      dismissableMask: false,
      transitionOptions: '0ms',
      styleClass: 'calendar-dialog',
    });
  }

  openContact() {
    this.contactRef = this.dialogService.open(ContactComponent, {
      showHeader: false,
      closeOnEscape: true,
      closable: true,
      dismissableMask: false,
      styleClass: 'contact-dialog',
    });
  }

  openGetTrained() {
    this.getTrainedRef = this.dialogService.open(GetTrainedComponent, {
      showHeader: false,
      closeOnEscape: true,
      closable: true,
      dismissableMask: false,
      styleClass: 'get-trained-dialog',
    });
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  ngOnInit(): void {
    // this.openGetTrained();
  }

  sidebarToggle(flag?) {
    this.showSidebarMobile = flag ? false : !this.showSidebarMobile;
    if (this.showSidebarMobile) {
      this._document.body.classList.add(`sidebar-open`);
    } else {
      this._document.body.classList.remove(`sidebar-open`);
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      if (this.quotesComponent) {
        this.startProgressbar();
      }
    }
  }

  startProgressbar() {
    this.resetProgressbar();
    this.percentTime = 0;
    this.isPause = false;

    this.tick = setInterval(() => {
      if (this.isPause === false) {
        this.percentTime += 1 / (this.time + 0.1);
        this.quotesProgress.nativeElement.style.width = this.percentTime + '%';
        if (this.percentTime >= 100) {
          this.quotesComponent.slickNext();
          this.startProgressbar();
        }
      }
    }, 10);
  }

  resetProgressbar() {
    this.quotesProgress.nativeElement.style.width = 0 + '%';
    clearTimeout(this.tick);
  }

  renderPhotos(images) {
    this.photos = [];
    setTimeout(() => {
      let photoss = [];
      this.shuffle(images);
      for (let i = 0; i < images.length; i++) {
        const src = images[i].src;
        const thumb = images[i].thumb;
        const album: any = {
          src: src,
          thumb: thumb,
        };
        photoss.push(album);
      }
      this.photos = photoss;
    }, 100);
  }

  renderQuotes(quotes) {
    this.quotes = this.shuffle(quotes);
  }

  openGallery(index: number): void {
    this._lightbox.open(this.photos, index, {
      wrapAround: false,
      showImageNumberLabel: true,
      showZoom: false,
      showRotate: false,
      centerVertically: true,
      enableTransition: false,
      fadeDuration: 0,
    });
  }
}
