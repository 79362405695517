<svg #svgMontenegro id="svg-montenegro" viewBox="0 0 280 305">
  <g class="svg-group-cities">
    <path *ngFor="let city of cities" [attr.d]="city.path" [ngStyle]="{ fillOpacity: setFill(city) }"
      (click)="mouseEnter($event, city)" (mouseenter)="mouseEnter($event, city)" (mouseleave)="mouseLeave($event)">
    </path>
  </g>
</svg>
<div class="map-legend">
  <span class="ml0"><span class="legend-text">0</span></span>
  <span class="ml1"><span class="legend-text">50</span></span>
  <span class="ml2"><span class="legend-text">100</span></span>
  <span class="ml3"><span class="legend-text">500</span></span>
  <span class="ml4"><span class="legend-text">1000</span></span>
  <span class="ml5"><span class="legend-text">5000</span></span>
  <span class="ml6"><span class="legend-text">10000+</span></span>
  <span class="ml7"></span>
</div>
<div #tooltip class="svg-montenegro-tooltip">
  <div class="svg-montenegro-tooltip__title">
    {{ activeCity.name }}
  </div>
  <div class="svg-montenegro-tooltip__val">
    <div class="svg-montenegro-tooltip__circle"></div>
    <div class="svg-montenegro-tooltip__text">{{ activeCity.count }}</div>
  </div>
</div>