import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'e-book-dialog',
  templateUrl: './e-book-dialog.component.html',
})
export class EBookDialogComponent implements OnInit, OnDestroy {
  documents: any[];
  title: string;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.documents = this.config.data.documents;
    this.title = this.config.data.title;
  }

  ngOnInit(): void {
    console.log(this.documents);
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
