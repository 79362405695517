import {
  sequence,
  trigger,
  stagger,
  animate,
  style,
  group,
  query as q,
  transition,
  keyframes,
  animateChild,
} from '@angular/animations';

const query = (s, a, o = { optional: true }) => q(s, a, o);

export const routerTransition = trigger('routerTransition', [
  // transition(
  //   'pick-your-avatar => home, gender-based-violence => *, politics => *, economy => *',
  //   [
  //     query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
  //     query(':enter', style({ transform: 'translateX(-100%)' })),
  //     sequence([
  //       group([
  //         query(':leave', [
  //           style({ transform: 'translateX(0%)' }),
  //           animate('1s ease-in-out', style({ transform: 'translateX(100%)' })),
  //         ]),
  //         query(':enter', [
  //           style({ transform: 'translateX(-100%)' }),
  //           animate('1s ease-in-out', style({ transform: 'translateX(0%)' })),
  //         ]),
  //       ]),
  //       query(':enter', animateChild()),
  //     ]),
  //   ]
  // ),
  transition(
    'home => pick-your-avatar, pick-your-avatar => *, home => *, pick-your-avatar => home, gender-based-violence => *, politics => *, economy => *',
    [
      query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
      query(':enter', style({ transform: 'translateX(100%)' })),
      sequence([
        // query(':leave', animateChild()),
        group([
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('1s ease-in-out', style({ transform: 'translateX(-100%)' })),
          ]),
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('1s ease-in-out', style({ transform: 'translateX(0%)' })),
          ]),
        ]),
        query(':enter', animateChild()),
      ]),
    ]
  ),
]);
