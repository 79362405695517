import { Component, OnInit } from '@angular/core';
import { CalendarDialogService } from '../calendar-dialog.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CalendarEventData } from './../calendar-dialog.component'


@Component({
  selector: 'app-calendar-info',
  templateUrl: './calendar-info.component.html',
  styleUrls: ['./calendar-info.component.scss']
})
export class CalendarInfoComponent implements OnInit {
  eventId: string;
  singleEvent: CalendarEventData;

  constructor(
    private calendarDialogService: CalendarDialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.eventId = this.config.data.id;

    this.calendarDialogService.getEvent(this.eventId).subscribe((res: any) => {
      this.singleEvent = res.data.findEvent;
      // console.log(this.singleEvent);
    });
  }


  onClose() {
    this.ref.close();
  }

}
