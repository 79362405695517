<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        Ask us
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>

<div class="p-dialog-container">
    <div class="form-container">
        <form [formGroup]="contactForm" (ngSubmit)="onFormSubmit(captchaRef)">
            <div class="form-control control__name">
                <label for="name" class="input-title">Name and surname
                    <span class="required-val"
                        *ngIf="!contactForm.get('name').valid && contactForm.get('name').touched">*</span>
                </label>
                <input type="text" id="name" class="control-input" formControlName="name">
            </div>
            <div class="form-control control__email">
                <label for="email" class="input-title">Email
                    <span class="required-val"
                        *ngIf="!contactForm.get('email').valid && contactForm.get('email').touched">*</span>
                </label>
                <input type="emial" id="email" class="control-input" formControlName="email">
            </div>
            <div class="form-control control__message">
                <label for="question" class="input-title">Question
                    <span class="required-val"
                        *ngIf="!contactForm.get('question').valid && contactForm.get('question').touched">*</span>
                </label>
                <textarea id="question" rows="5" cols="5" class="control-input" formControlName="question"></textarea>
            </div>
            <div class="form-control control__checkbox">
                <label for="checkbox">
                    <input type="checkbox" name="checkbox" id="checkbox" (change)="acceptedPolicy = !acceptedPolicy">
                    <span class="label-text">I accept the terms of use and privacy policy</span>
                </label>
            </div>
            <re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="validCaptcha($event)"></re-captcha>
            <div class="send-btn-wrap">
                <button type="submit" class="send-btn" [disabled]="!acceptedPolicy || !contactForm.valid">Send</button>
                <!-- [disabled]="" -->
            </div>
        </form>
    </div>
</div>