import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/browser/environment';

@Injectable({ providedIn: 'root' })
export class SendFormDataService {
    constructor(private http: HttpClient) { }

    submitGetTrainetForm(form: { name: string, email: string, chosenTraining: any, message: string }) {
        // console.log(form);
        const { name, email, message } = form;
        const chosenTraining = form.chosenTraining.name
        return this.http.post(`${environment.api}/get-trained/client`, {
            name,
            email,
            chosenTraining,
            message
        });
    }


    submitContactForm(form: { name: string, email: string, question: string }) {
        // console.log(form);
        return this.http.post(`${environment.api}/question/client`, {
            ...form,
        });
    }

    submitViolenceForm(form: {
        city: string,
        createdAt: string,
        description: string,
        incident_happend: string,
        latitude: string,
        longitude: string,
    }) {
        return this.http.post(`${environment.api}/violence-report/client`, {
            ...form
        })
    }


    submitStoryForm(form: { title: string, content: string }, category: string) {
        return this.http.post(`${environment.api}/story/client`, {
            ...form,
            category
        });
    }







}
