import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class WrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
