<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        Event
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>
<div class="p-dialog-container" *ngIf="singleEvent">
    <div class="dialog-wrap">
        <h3>Title</h3>
        <p class="dialog-data">{{singleEvent.title}}</p>
    </div>
    <div class="dialog-wrap">
        <h3>Start time</h3>
        <p class="dialog-data">{{singleEvent.eventStartConverted}}, {{singleEvent.eventStartTime}}h</p>
    </div>
    <div class="dialog-wrap">
        <h3>Time to finish</h3>
        <p class="dialog-data">{{singleEvent.eventEndConverted}}, {{singleEvent.eventEndTime}}h</p>
    </div>
    <div class="dialog-wrap">
        <h3>Location</h3>
        <p class="dialog-data">{{singleEvent.location}}</p>
    </div>
    <div class="dialog-wrap">
        <h3>Description</h3>
        <div class="dialog-data">
            <div class="html-container" [innerHtml]="singleEvent.description"></div>
        </div>
    </div>
</div>