import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {} from 'googlemaps';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendFormDataService } from '../services/sendFormData.service';
import { ToastrService } from 'ngx-toastr';
import { MapSettingsService } from '../services/mapSettings.service';

@Component({
  selector: 'app-report-violenc-dialog',
  templateUrl: './report-violence-dialog.component.html',
})
export class ReportViolenceComponent implements OnInit, AfterViewInit, OnDestroy {
  violenceForm: FormGroup;
  acceptedPolicy: boolean = false;

  recaptchaStr = '';

  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapProperties: any;
  markerSettings: any;
  cityBounds: google.maps.LatLngBounds;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private mapSettingsService: MapSettingsService,
    private sendFormData: SendFormDataService,
    private toastr: ToastrService
  ) {
    // this.mapProperties = this.mapSettingsService.mapProperties;
    // this.markerSettings = this.mapSettingsService.markerSettings;
  }

  ngOnInit(): void {
    this.violenceForm = new FormGroup({
      incident_happend: new FormControl(null, Validators.required),
      mobilePhone: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      // city: new FormControl(null, Validators.required),
      // longitude: new FormControl(null, Validators.required),
      // latitude: new FormControl(null, Validators.required),
    });
  }

  ngAfterViewInit() {
    let self = this;

    // this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    // this.marker = new google.maps.Marker({
    //   map: this.map,
    //   draggable: true,
    //   icon: this.markerSettings.icons.location.url,
    //   animation: google.maps.Animation.BOUNCE,
    // });

    // let currentPosition;

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       console.log(position);

    //       currentPosition = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //       this.map.setZoom(12);
    //       this.marker.setPosition(currentPosition);
    //       this.map.panTo(currentPosition);

    //       this.violenceForm.patchValue({
    //         city: 'Podgorica',
    //         latitude: currentPosition.lat,
    //         longitude: currentPosition.lng,
    //       });
    //     },
    //     () => {
    //       this.setDefaultLocation();
    //     }
    //   );
    // } else {
    //   this.setDefaultLocation();
    // }

    // google.maps.event.addListener(this.marker, 'position_changed', function () {
    //   self.violenceForm.patchValue({
    //     latitude: self.marker.getPosition().lat().toString(),
    //     longitude: self.marker.getPosition().lng().toString(),
    //   });
    // });

    // google.maps.event.addListener(this.marker, 'click', toggleBounce);
    // google.maps.event.addListener(this.marker, 'dragstart', function () {
    //   self.marker.setAnimation(null);
    // });

    // function toggleBounce() {
    //   if (self.marker.getAnimation() !== null) {
    //     self.marker.setAnimation(null);
    //   } else {
    //     self.marker.setAnimation(google.maps.Animation.BOUNCE);
    //   }
    // }
  }

  setDefaultLocation() {
    let currentPositionPg = {
      lat: 42.428398626284,
      lng: 19.263371906579508,
    };
    this.map.setZoom(12);
    this.marker.setPosition(currentPositionPg);
    this.map.panTo(currentPositionPg);

    this.violenceForm.patchValue({
      city: 'Podgorica',
      latitude: currentPositionPg.lat,
      longitude: currentPositionPg.lng,
    });
  }

  public validCaptcha(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      console.log('123');
      this.onSubmit();
    }
  }

  onFormSubmit(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }

  onSubmit() {
    if (!this.violenceForm) {
      return;
    }

    if (this.violenceForm.valid) {
      this.sendFormData.submitViolenceForm(this.violenceForm.value).subscribe(
        (res: any) => {
          if (res.status === 'Success') {
            console.log(res);

            this.toastr.success('You have successfully reported violence!', 'Success');

            this.violenceForm.reset();
            this.ref.close();
          }
        },
        (res) => {
          this.toastr.error(res.error.message, 'Error');
        }
      );
    } else {
      this.toastr.error('Inquiry is invalid! Please enter all required fields!', 'Error');
    }
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
