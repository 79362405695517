import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendFormDataService } from '../services/sendFormData.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-get-trained-dialog',
  templateUrl: './get-trained-dialog.component.html',
})
export class GetTrainedComponent implements OnInit, OnDestroy {
  getTrainedForm: FormGroup;
  acceptedPolicy: boolean = false;
  submited = false;
  chosenTraining: any[];

  recaptchaStr = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private sendFormData: SendFormDataService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.chosenTraining = [
      { name: 'Rodna ravnopravnost i osnaživanje žena' },
      { name: 'Rodna ravnopravnost u političkim partijama' },
      { name: 'Rodna ravnopravnost u komunikacijama i medijima' },
      { name: 'Lidersvo u borbi za ravnopravnost' },
      { name: ' Integrisanje principa rodne ravnopravnosti u politike, zakone i projekte' },
      { name: 'Integrisanje principa rodne ravnopravnosti u rad institucija' },
      { name: 'Žene, mir, bezbjednost' },
      { name: 'Žensko preduzetništvo – od ideje do biznis plana' },
      { name: 'Other' },
    ];

    this.getTrainedForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      chosenTraining: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
    });
  }

  public validCaptcha(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      console.log('123');
      this.onSubmit();
    }
  }

  onFormSubmit(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }

  onSubmit() {
    if (!this.getTrainedForm) {
      return;
    }

    if (this.getTrainedForm.valid) {
      this.loadingService.showLoadingBar();
      this.sendFormData.submitGetTrainetForm(this.getTrainedForm.value).subscribe(
        (response: any) => {
          console.log(response);
          this.loadingService.hideLoadingBar();
          if ((response.status = 'Succes')) {
            this.toastr.success('Uspješno ste ispunili formu!', 'Uspješno');
          }
          this.getTrainedForm.reset();
          this.ref.close();
        },
        (err) => {
          this.toastr.error(err.error.message, 'Greška');
        }
      );
    } else {
      this.toastr.error('Upit nije validan! Molimo unesite sva obavezna polja!', 'Greška');
    }
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
