import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutesSharedModule } from 'src/app/shared/routes-shared.module';
import { ContentModule } from '../components/content/content.module';
import { HeaderModule } from '../components/header/header.module';
import { WrapperComponent } from './wrapper.component';

@NgModule({
  declarations: [WrapperComponent],
  imports: [RouterModule, RoutesSharedModule, ContentModule, HeaderModule],
  exports: [WrapperComponent],
})
export class WrapperModule {}
