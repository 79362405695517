import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';
const routes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    data: { animation: 'home' },
    loadChildren: () => import('./main/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'choose-your-guides',
    canActivateChild: [MetaGuard],
    data: { animation: 'pick-your-avatar' },
    loadChildren: () =>
      import('./main/pick-your-avatar/pick-your-avatar.module').then((m) => m.PickYourAvatarModule),
  },
  {
    path: 'gender-based-violence',
    canActivateChild: [MetaGuard],
    data: { animation: 'gender-based-violence' },
    loadChildren: () =>
      import('./main/victim-of-violence/victim-of-violence.module').then(
        (m) => m.VictimOfViolenceModule
      ),
  },
  {
    path: 'politics',
    canActivateChild: [MetaGuard],
    data: { animation: 'politics' },
    loadChildren: () =>
      import('./main/politician/politician.module').then((m) => m.PoliticianModule),
  },
  {
    path: 'women-of-montenegro',
    canActivateChild: [MetaGuard],
    data: { animation: 'politika' },
    loadChildren: () => import('./main/women/women.module').then((m) => m.WomenModule),
  },
  {
    path: 'economy',
    canActivateChild: [MetaGuard],
    data: { animation: 'economy' },
    loadChildren: () =>
      import('./main/entrepreneur/entrepreneur.module').then((m) => m.EntrepreneurModule),
  },
  {
    path: 'peace-and-security',
    canActivateChild: [MetaGuard],
    data: { animation: 'peace-and-security' },
    loadChildren: () =>
      import('./main/peace-and-security/peace-and-security.module').then(
        (m) => m.PeaceAndSecurityModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, {
  initialNavigation: 'enabled',
});
