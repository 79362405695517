import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, Inject, PLATFORM_ID } from '@angular/core';
import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingService } from './shared/services/loading.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewChecked {
  constructor(
    private loadingService: LoadingService,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private _document: Document,
    public breakpointObserver: BreakpointObserver
  ) {
    if (this.deviceService.isMobile()) {
      this._document.documentElement.classList.add('mobile-device');
    }
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(min-width: 480px)']).subscribe((state: BreakpointState) => {
      if (this.deviceService.isMobile() || !state.matches) {
        this._document.documentElement.classList.add('mobile-device');
      } else {
        this._document.documentElement.classList.remove('mobile-device');
      }
    });
  }

  ngAfterViewChecked() {
    if (isPlatformBrowser(this._platformId)) {
      this.loadingService.hideBigLoading(0);
    }
  }
}
