<div class="logo" [@logoAnimation]>
    <a routerLink="/">
        <svg-icon src="assets/logo.svg"></svg-icon>
    </a>
</div>

<div class="mobile-menu" [@mobileMenuTransition]>
    <div class="hamburger" (click)="sidebarToggle()">
        <div class="hamburger-box">
            <div class="hamburger-inner"></div>
        </div>
    </div>
</div>

<div class="sidebar-overlay" (click)="sidebarToggle(true)"></div>

<div class="right-container" [@headerTransition]>
    <div class="right-container__content">

        <div class="quotes" (mouseenter)="isPause = true" (mouseleave)="isPause = false">
            <h3>
                <span>Did you know?</span>
                <div class="quotes-progress-bar" #quotesProgress></div>
            </h3>
            <ngx-slick-carousel class="carousel" [config]="quotesSlideConfig" #quotesComponent
                (beforeChange)="startProgressbar()">
                <div ngxSlickItem *ngFor="let quote of quotes" class="slide">
                    <p [innerHtml]="quote"></p>
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="photo-slider">
            <h3> Album</h3>
            <div class="photo-slider__frame">
                <ngx-slick-carousel class="photo-slider__wrapper" [config]="sliderConfig">
                    <div ngxSlickItem *ngFor="let photo of photos; index as i" class="photo-slider__slide">
                        <div class="photo-slider__item">
                            <a (click)="openGallery(i)">
                                <span class="photo-slider__item__image">
                                    <img [attr.data-lazy]="photo.thumb" />
                                </span>
                                <span class="zoom-in">
                                    <svg-icon src="assets/zoom-in.svg"></svg-icon>
                                </span>
                            </a>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="photo-slider__arrows">
                </div>
            </div>
        </div>

        <div class="right-icons">

            <div class="get-trained-icon" (click)="openGetTrained()" tooltipPosition="bottom" pTooltip="Get trained"
                tooltipStyleClass="get-trained-tooltip">
                <svg-icon src="assets/icons/gettrained.svg"></svg-icon>
            </div>
            <div class="contact-icon" (click)="openContact()" tooltipPosition="bottom" pTooltip="Ask us"
                tooltipStyleClass="contact-tooltip">
                <svg-icon src="assets/icons/contact.svg"></svg-icon>
            </div>
            <div class="calendar-icon" (click)="openCalendar()" tooltipPosition="bottom"
                tooltipStyleClass="calendar-tooltip" pTooltip="Calendar of gender events">
                <svg-icon src="assets/icons/calendar.svg"></svg-icon>
            </div>
            <a href="https://sdgs.un.org/goals" target="_blank" tooltipPosition="bottom" tooltipStyleClass="sdg-tooltip"
                pTooltip="Sustainable Development Goals">
                <svg-icon src="assets/icons/sdg.svg"></svg-icon>
            </a>
        </div>
        <div class="project-logos">
            <div class="project-logo"><a href="https://www.entwicklung.at">
                    <svg-icon src="assets/sponsors/australian.svg"></svg-icon>
                </a>
            </div>
            <div class="project-logo">
                <img src="assets/sponsors/eu-logo.png" />
            </div>
            <div class="project-logo">
                <svg-icon src="assets/sponsors/mmp.svg"></svg-icon>
            </div>
            <div class="project-logo">
                <svg-icon src="assets/sponsors/undp.svg"></svg-icon>
            </div>
        </div>
        <div class="bottom">
            <div class="lang-switcher">
                <a href="https://www.rodnamapa.me/">
                    <svg-icon src="assets/mne-lang.svg"></svg-icon>
                </a>
            </div>

            <div class="facebook">
                <a href="https://www.facebook.com/UNDP-Gender-Programme-Montenegro-134396116646047" target="_blank">
                    <svg-icon src="assets/fb.svg"></svg-icon>
                </a>
            </div>
        </div>
    </div>
</div>