<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        {{victimStory ? victimStory.title : ''}}
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>
<div class="p-dialog-container">
    <div class="story-container__wrapper" *ngIf="!showSpinner">
        <div class="html-container" [innerHtml]="victimStory.content"></div>
    </div>
    <div class="loading-overlay" id="loading-overlay" *ngIf="showSpinner">
        <div class="loading-overlay__center">
            <div class="loading-overlay__loading-bar">
                <div class="loader">Loading...</div>
            </div>
        </div>
    </div>
</div>