<div class="p-dialog-header">
    <h4 class="p-dialog-header__title">
        Share your story
    </h4>
    <div class="p-dialog-header__close">
        <button class="btn-close" (click)="onClose()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
                    stroke-width="1.50987" />
            </svg>
        </button>
    </div>
</div>
<div class="p-dialog-container">
    <div class="form-container">
        <form [formGroup]="storyForm" (ngSubmit)="onFormSubmit(captchaRef)">
            <div class="form-control control__name">
                <label for="title" class="input-title">The title of the story
                    <span class="required-val"
                        *ngIf="!storyForm.get('title').valid && storyForm.get('title').touched">*</span>
                </label>
                <input type="text" id="name" class="control-input" formControlName="title">
            </div>
            <div class="form-control control__message">
                <label for="content" class="input-title">Story description
                    <span class="required-val"
                        *ngIf="!storyForm.get('content').valid && storyForm.get('content').touched">*</span>
                </label>
                <quill-editor id="content" [modules]="quillConfig" class="control-quill-editor" #editor
                    formControlName="content">
                </quill-editor>
                <!-- <textarea id="content" rows="5" cols="5" class="control-input" formControlName="content"></textarea> -->
            </div>
            <div class="form-control control__checkbox">
                <label for="checkbox">
                    <input type="checkbox" name="checkbox" id="checkbox" (change)="acceptedPolicy = !acceptedPolicy">
                    <span class="label-text">I accept the terms of use and privacy policy</span>
                </label>
            </div>
            <re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="validCaptcha($event)"></re-captcha>
            <div class="send-btn-wrap">
                <button type="submit" class="send-btn" [disabled]="!acceptedPolicy || !storyForm.valid">Send</button>
            </div>
        </form>
    </div>
</div>