import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MapSettingsService {
  MONTENEGRO_BOUNDS = {
    north: 43.578024,
    south: 41.806486,
    west: 18.105382,
    east: 20.525677,
  };

  MONTENEGRO_LAT_LONG = {
    latitude: 42.6695814,
    longitude: 19.4874586,
  };

  mapStyles = [
    {
      featureType: 'all',

      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          weight: '4',
        },
        {
          color: '#187CA7',
        },
        {
          lightness: '0',
        },
        {
          saturation: '0',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];

  mapProperties: any = {
    mapTypeId: 'roadmap',
    maxZoom: 18,
    minZoom: 8.9,
    zoom: 9,
    streetViewControl: false,
    center: { lat: 42.6695814, lng: 19.4874586 },
    styles: this.mapStyles,
    zoomControl: true,
    panControl: true,
    clickableIcons: false,
  };

  markerSettings: any = {
    icons: {
      realEstate: {
        url: `assets/map-icons/marker.svg`,
        size: new google.maps.Size(22, 22),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(11, 11),
        scaledSize: new google.maps.Size(22, 22),
      },
      clickedRealEstate: {
        url: `assets/map-icons/clicked-marker.svg`,
        size: new google.maps.Size(54, 54),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(27, 27),
        scaledSize: new google.maps.Size(54, 54),
      },
      location: {
        url: `/assets/map-icons/location.svg`,
        size: new google.maps.Size(28, 35),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 35),
        scaledSize: new google.maps.Size(28, 35),
      },
      contactLocation: {
        url: `/assets/map-icons/contactLocation.svg`,
        size: new google.maps.Size(55, 76),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(28, 76),
        scaledSize: new google.maps.Size(55, 76),
      },
    },
    draggable: false,
    clickable: true,
  };

  clusterOptions: any = {
    maxZoom: 17,
    gridSize: 30,
    ignoreHidden: true,
    styles: [
      {
        textColor: 'white',
        url: '/assets/map-icons/cluster-40.png',
        className: 'cluster-container',
        textSize: 14,
        height: 40,
        width: 40,
      },
      {
        textColor: 'white',
        url: '/assets/map-icons/cluster-50.png',
        className: 'cluster-container',
        textSize: 15,
        height: 50,
        width: 50,
      },
      {
        textColor: 'white',
        url: '/assets/map-icons/cluster-60.png',
        className: 'cluster-container',
        textSize: 16,
        height: 60,
        width: 60,
      },
      {
        textColor: 'white',
        url: '/assets/map-icons/cluster-70.png',
        className: 'cluster-container',
        textSize: 17,
        height: 70,
        width: 70,
      },
      {
        textColor: 'white',
        url: '/assets/map-icons/cluster-80.png',
        className: 'cluster-container',
        textSize: 18,
        height: 80,
        width: 80,
      },
    ],
  };
}
