import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';

import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarDialogService } from './calendar-dialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarInfoComponent } from './calendar-info/calendar-info.component';
import listPlugin from '@fullcalendar/list';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface CalendarEventData {
  description: string;
  eventEnd: number;
  eventEndConverted: string;
  eventEndTime: string;
  eventStart: number;
  eventStartConverted: string;
  eventStartTime: string;
  location: string;
  title: string;
  setAllDay?: any;
  _id: string;
}

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
})
export class CalendarComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  events: CalendarEventData[];
  calendarOptions: any;
  newEvents: any[] = [];
  private calendarApi: any;

  showSpinner: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer, // private eventService: EventService
    private calendarDialogService: CalendarDialogService,
    public dialogService: DialogService,
    private deviceService: DeviceDetectorService,
    public breakpointObserver: BreakpointObserver
  ) {
    const name = Calendar.name;
  }

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      headerToolbar: {
        left: 'dayGridMonth,listWeek',
        center: 'title',
        right: 'prev,next',
      },
      buttonText: {
        today: 'Today',
        month: 'Calendar',
        week: 'Week',
        day: 'day',
        list: 'list',
      },
      customButtons: {
        prev: {
          text: 'Prev',
          click: this.prevMonth.bind(this),
        },
        next: {
          text: 'Next',
          click: this.nextMonth.bind(this),
        },
      },
      eventLimit: true,
      eventClick: (arg) => {
        this.showDialog(arg);
      },
      editable: false,
      showNonCurrentDates: true,
    };

    this.breakpointObserver.observe(['(min-width: 480px)']).subscribe((state: BreakpointState) => {
      if (this.deviceService.isMobile() || !state.matches) {
        this.calendarOptions.initialView = 'listWeek';
      }
    });

    setTimeout(() => {
      this.calendarApi = this.calendarComponent.getApi();
      let currentDate = this.calendarApi.view.currentStart;
      let backendStartDate = new Date(currentDate).toLocaleString().split(',')[0];
      let backendEndDate = new Date(this.calendarApi.view.currentEnd - 1)
        .toLocaleString()
        .split(',')[0];

      this.getCalendarEvents(backendStartDate, backendEndDate);
    }, 0);
  }

  // Get Calendar From Backend
  getCalendarEvents(startDate, endDate) {
    this.showSpinner = true;
    this.calendarDialogService.getEvents(startDate, endDate).subscribe((events: any) => {
      // console.log(events);
      if (events.status === 'Success') {
        console.log(events);
        this.showSpinner = false;
        this.events = events.data.see;
        this.setCalendarEvents();
      }
    });
  }

  setCalendarEvents() {
    this.events.forEach((event) => {
      event.eventStartConverted = new Date(event.eventStart).toLocaleDateString('en-CA');
      event.eventEndConverted = new Date(event.eventEnd).toLocaleDateString('en-CA') + 'T23:59:00';
      // console.log(event.eventStartConverted, event.eventEndConverted);

      const { _id: id, eventStartConverted: start, eventEndConverted: end, title, ...res } = event;

      this.newEvents.push({ id, start, end, title, ...res });
    });
    this.calendarOptions.events = this.newEvents;
  }

  // On Click call prev month
  prevMonth() {
    this.calendarOptions.events = [];
    this.newEvents = [];

    this.calendarApi.prev();

    let backendStartDate = new Date(this.calendarApi.view.currentStart)
      .toLocaleString()
      .split(',')[0];
    let backendEndDate = new Date(this.calendarApi.view.currentEnd - 1)
      .toLocaleString()
      .split(',')[0];

    this.getCalendarEvents(backendStartDate, backendEndDate);
  }

  // On Click call next month
  nextMonth() {
    this.calendarOptions.events = [];
    this.newEvents = [];

    this.calendarApi.next();

    let backendStartDate = new Date(this.calendarApi.view.currentStart)
      .toLocaleString()
      .split(',')[0];
    let backendEndDate = new Date(this.calendarApi.view.currentEnd - 1)
      .toLocaleString()
      .split(',')[0];

    this.getCalendarEvents(backendStartDate, backendEndDate);
  }

  showDialog(arg) {
    // console.log(this.newEvents);

    const eventRef = this.dialogService.open(CalendarInfoComponent, {
      data: {
        id: arg.event.id,
      },
      showHeader: false,
      closeOnEscape: true,
      closable: true,
      dismissableMask: false,

      styleClass: 'calendar-info',
    });
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
