import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-svg-montenegro',
  templateUrl: './svg-montenegro.component.html',
  styleUrls: ['./svg-montenegro.component.scss'],
})
export class SvgMontenegroComponent implements OnInit, AfterViewInit {
  @ViewChild('svgMontenegro') svg: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;
  @Input() public cities;

  activeCity = {
    name: '',
    count: 0,
  };

  paths: any = [
    {
      name: 'Andrijevica',
      path:
        'M167 136L168 129L169 128L171 129L172 133L173 133L176 132L179 136L182 136L186 137L189 138L191 140L193 138L196 140L193 145L194 147L194 149L197 151L198 151L201 153L203 156L201 157L201 158L200 158L199 159L197 163L196 164L195 164L195 165L194 166L194 170L195 170L197 173L195 173L194 174L193 174L193 173L186 173L186 172L185 171L185 170L184 170L184 167L185 166L184 166L184 165L182 165L182 162L181 162L181 161L181 162L180 161L180 162L179 162L178 163L178 162L178 163L177 163L177 165L176 165L176 166L176 165L176 166L175 166L175 167L173 167L173 165L172 164L172 158L171 158L170 156L172 153L173 149L171 143L171 139z',
    },
    {
      name: 'Bar',
      path:
        'M127 257L127 257zM130 258L131 258zM130 258L130 258zM131 258L131 259zM105 278L105 278zM135 262L135 262zM136 261L136 261zM135 261L135 261zM135 261L136 261zM133 260L133 260zM132 260L132 260zM130 259L131 259L131 260L131 259L130 259zM79 227L83 227L84 229L89 233L92 229L92 226L96 225L98 225L99 226L98 226L98 227L99 227L99 226L100 226L100 227L101 228L100 228L101 228L100 228L100 229L101 229L101 230L102 230L101 230L101 231L100 231L100 232L99 234L100 232L101 232L101 233L99 233L99 234L100 233L101 233L101 235L103 235L103 236L102 236L102 237L104 237L105 236L106 237L107 236L108 237L108 238L109 237L110 238L111 238L111 239L112 239L112 240L111 240L111 241L112 241L112 244L113 244L113 245L114 245L114 247L114 246L114 247L115 247L115 248L116 248L116 250L117 250L117 251L118 251L118 252L119 252L119 253L119 252L119 253L120 253L120 254L120 253L121 253L121 254L122 254L122 255L123 255L123 256L124 256L124 257L125 257L125 256L126 256L125 256L125 257L127 257L127 258L128 258L128 259L129 259L129 260L131 260L131 261L134 261L134 260L134 261L135 261L135 262L136 262L136 263L137 263L136 264L136 267L130 267L126 269L125 269L118 265L118 270L115 271L120 274L121 280L119 283L118 283L110 279L107 278L106 278L106 275L107 275L107 274L106 274L106 271L105 271L106 271L105 270L106 270L106 269L105 269L105 268L103 268L103 267L102 266L101 266L100 265L100 264L98 264L98 263L97 263L97 262L96 261L97 261L97 259L97 262L97 261L98 262L98 261L99 261L98 261L98 260L99 261L99 260L98 260L98 259L98 260L99 260L99 259L98 259L99 259L99 258L98 258L98 257L97 257L97 256L96 256L96 255L95 255L95 253L90 253L91 253L90 253L90 252L89 252L89 253L88 253L88 249L86 249L86 248L85 248L90 245L88 242L86 241L85 238L80 232L79 230zM120 253L120 253zM120 253L120 253zM120 252L120 251L120 252zM120 251L120 251zM117 249L118 249L117 249zM116 248L117 248L116 248zM116 247L116 247zM113 244L114 244L113 244zM114 244L114 243L115 243L114 243L115 243L115 244L114 244zM114 243L114 243zM104 235L104 236zM101 233L101 233zM102 230L102 230z',
    },
    {
      name: 'Berane',
      path:
        'M167 123L167 122L168 120L169 120L171 118L171 117L176 116L179 116L180 117L181 117L182 115L183 115L185 114L187 114L188 113L189 113L189 110L191 108L193 108L193 111L196 114L198 114L198 115L200 115L206 117L215 123L216 124L219 125L220 126L217 128L219 129L217 132L219 133L220 133L218 135L218 136L223 141L222 141L222 142L221 142L220 143L219 143L219 147L218 147L218 150L219 150L219 151L220 151L220 152L218 152L218 153L216 153L216 154L215 154L212 153L211 154L209 154L209 155L208 154L207 154L206 155L205 155L205 156L203 156L201 153L198 151L197 151L194 149L194 147L193 145L196 140L193 138L191 140L189 138L182 136L179 136L176 132L173 133L172 133L171 129L169 128L171 126L167 124z',
    },
    {
      name: 'Bijelo Polje',
      path:
        'M140 88L141 86L142 85L141 83L144 83L147 80L147 78L150 73L152 72L153 71L152 70L152 69L154 63L156 63L158 59L161 59L161 60L164 60L164 61L167 61L167 62L168 63L168 64L169 64L169 65L170 66L169 66L169 68L170 68L170 69L174 69L174 70L175 70L175 72L182 72L182 73L183 74L185 74L185 73L186 73L187 74L188 74L188 77L187 77L187 78L188 78L188 79L189 79L188 79L189 80L191 80L191 81L192 81L192 82L193 82L193 81L195 81L195 85L196 85L196 84L197 84L199 82L200 82L200 83L201 83L201 84L202 85L203 85L204 86L205 86L205 87L205 86L206 85L208 85L208 84L210 84L210 83L211 83L212 82L212 83L213 83L213 84L212 84L212 88L214 90L214 92L215 93L216 93L216 94L217 94L220 95L220 96L221 96L221 97L222 97L222 98L223 99L223 100L224 101L215 106L214 107L213 106L209 109L206 109L200 115L198 115L198 114L196 114L193 111L193 108L191 108L189 110L189 113L188 113L187 114L185 114L183 115L182 115L181 117L180 117L179 116L176 116L171 117L171 112L169 112L165 109L166 108L167 104L167 100L166 99L162 99L161 97L156 97L154 94L152 92L147 92L145 93L141 89z',
    },
    {
      name: 'Budva',
      path:
        'M60 228L60 226L61 223L63 221L62 219L61 218L61 216L62 215L65 218L68 219L69 219L71 221L74 221L78 225L79 227L79 230L80 232L85 238L86 241L88 242L90 245L85 248L84 248L84 247L83 247L83 245L84 245L84 244L83 244L83 243L81 243L81 242L80 242L80 241L78 241L78 240L76 240L77 239L77 240L77 239L76 239L77 239L76 239L75 238L75 236L74 236L74 232L73 232L74 232L73 231L73 229L72 229L72 228L69 228L69 229L69 227L67 227L66 228L67 228L66 228L66 229L66 228L66 229L66 228L65 229L66 229L65 229L63 227L62 227L62 229L61 229L61 228L60 228z',
    },
    {
      name: 'Cetinje',
      path:
        'M101 226L102 226L101 226zM48 169L52 166L52 165L50 161L52 160L52 157L54 158L55 155L56 155L60 157L68 157L70 158L71 161L73 163L75 164L77 167L77 169L79 171L80 173L84 177L90 181L87 185L87 186L86 188L88 192L86 194L89 196L90 198L92 199L92 202L93 203L94 203L95 202L97 204L98 207L98 209L108 215L109 223L102 227L102 225L101 225L101 226L101 225L100 225L100 222L100 223L97 223L97 224L98 224L98 225L96 225L92 226L92 229L89 233L84 229L83 227L79 227L78 225L74 221L71 221L69 219L68 219L65 218L62 215L61 212L61 210L59 209L58 208L58 206L59 205L60 202L61 201L61 197L60 193L56 185L52 181L52 180L53 179L53 178L52 177L50 176z',
    },
    {
      name: 'Danilovgrad',
      path:
        'M79 171L81 169L85 168L86 169L88 169L90 168L90 166L87 163L87 160L92 155L93 156L94 156L96 155L98 155L101 156L106 156L108 154L109 154L112 156L114 156L116 155L116 154L113 154L112 153L113 152L113 151L112 149L114 147L114 144L116 146L117 149L119 153L121 155L121 158L122 160L124 161L124 162L123 163L119 174L117 178L115 180L114 186L115 187L116 187L114 193L113 194L110 194L109 193L101 195L90 195L90 193L88 192L86 188L87 186L87 185L90 181L84 177L80 173z',
    },
    {
      name: 'Gusinje',
      path:
        'M183 182L183 181L184 181L184 175L185 175L185 176L185 175L187 175L187 173L193 173L193 174L194 174L195 173L197 173L195 170L194 170L194 167L198 171L200 171L202 172L203 173L204 173L204 174L205 174L205 176L202 176L202 177L203 179L203 185L204 188L204 190L203 191L203 192L202 192L202 191L201 191L201 190L200 190L200 192L199 192L200 192L199 193L198 193L198 192L197 192L197 193L196 194L196 193L195 193L195 195L194 195L194 196L194 195L192 195L192 194L191 194L191 193L189 193L188 191L188 190L186 190L186 189L185 188L185 185L184 185L184 183L183 183L183 182z',
    },
    {
      name: 'Herceg Novi',
      path:
        'M14 186L14 185L15 185L15 180L16 180L16 179L15 179L17 179L17 178L18 178L18 179L18 178L19 178L19 179L19 178L20 178L19 178L20 178L20 177L21 177L21 176L22 176L22 175L23 175L23 174L24 174L24 175L27 175L27 176L28 176L28 177L28 176L28 177L29 177L28 177L29 177L29 179L30 179L29 180L30 180L30 181L31 182L33 182L34 183L36 183L36 185L37 185L37 186L38 186L39 185L43 185L43 186L44 186L44 188L44 187L43 188L42 188L42 189L41 190L36 190L35 191L35 190L33 191L33 192L34 192L34 193L35 193L36 194L40 194L41 193L41 194L45 194L46 193L47 193L47 194L46 194L46 195L45 195L46 195L45 195L45 196L46 197L44 197L44 198L43 198L44 198L43 198L42 199L43 199L43 198L42 198L42 199L41 199L41 200L38 200L38 201L34 201L34 200L33 200L33 199L32 199L32 198L32 199L32 198L32 199L32 198L26 198L26 197L24 197L24 198L23 198L24 199L23 199L24 199L24 201L25 201L27 203L26 203L25 202L24 202L22 200L22 199L20 197L20 196L19 196L19 195L18 195L17 194L16 194L16 193L15 193L14 192L15 192L15 189L16 189L16 188L15 187zM32 208L32 208zM30 208L31 208L30 208zM29 203L30 203L30 202L31 202L31 201L33 201L33 202L35 202L36 203L38 203L39 204L39 205L38 206L40 206L40 207L41 207L41 211L42 212L42 213L41 213L41 212L40 212L40 214L39 214L39 213L37 213L37 212L35 212L35 211L34 211L35 211L35 210L33 210L33 209L33 210L33 209L32 209L32 208L33 208L33 207L32 207L31 206L31 207L31 206L30 206L30 205L29 205L29 204z',
    },
    {
      name: 'Kolašin',
      path:
        'M112 143L113 139L113 137L114 135L118 131L117 129L113 127L113 123L114 121L115 120L114 118L116 118L118 117L121 117L122 118L126 118L128 116L130 116L131 117L132 117L133 116L133 113L135 114L138 117L141 117L145 118L147 119L150 119L155 117L156 118L157 118L159 116L161 117L166 118L169 120L168 120L167 122L167 124L171 126L168 129L167 136L171 139L171 143L173 149L172 153L170 156L171 158L168 158L166 159L166 157L162 154L160 155L159 154L150 154L148 153L147 154L144 154L145 157L142 159L141 159L139 158L139 160L140 161L140 162L138 162L136 164L134 162L132 161L128 162L124 162L124 161L122 160L121 158L121 155L119 153L117 149L116 146L114 144z',
    },
    {
      name: 'Kotor',
      path:
        'M28 177L29 177L36 174L36 172L38 170L42 170L45 167L46 167L48 169L50 176L52 177L53 178L53 179L52 180L52 181L56 185L60 193L61 197L61 201L60 202L59 205L58 206L58 208L59 209L61 210L61 212L62 215L61 216L61 218L62 219L63 221L61 223L60 226L60 230L58 230L58 229L57 229L57 228L56 228L56 227L55 227L55 226L54 226L54 225L53 224L53 223L52 223L51 222L50 222L50 218L49 217L49 216L48 216L48 217L48 216L47 215L47 214L48 214L48 215L49 215L49 213L48 213L48 212L51 210L53 210L53 208L54 208L53 207L54 207L53 206L53 203L54 202L51 198L51 197L50 197L50 196L49 196L48 195L48 194L47 194L49 194L49 195L50 194L51 194L51 195L52 195L53 196L54 196L54 197L55 197L55 198L56 198L56 199L55 199L56 200L56 203L58 203L57 202L57 203L58 202L57 203L57 202L58 202L57 202L57 191L55 191L55 192L53 192L53 193L53 192L48 192L48 191L47 190L48 190L48 187L46 187L46 189L44 189L44 190L43 190L43 191L42 191L42 192L46 192L46 193L47 193L46 193L45 194L41 194L41 193L40 194L36 194L35 193L34 193L34 192L33 192L33 191L35 190L35 191L36 190L41 190L42 189L42 188L43 188L44 187L44 188L44 186L43 186L43 185L39 185L38 186L37 186L37 185L36 185L36 183L34 183L33 182L31 182L30 181L30 180L29 180L30 179L29 179L29 177L28 177z',
    },
    {
      name: 'Mojkovac',
      path:
        'M127 103L128 97L132 96L133 95L135 94L135 91L134 90L134 89L137 90L140 90L141 89L145 93L147 92L152 92L154 94L156 97L161 97L162 99L166 99L167 100L167 104L166 108L165 109L169 112L171 112L171 118L169 120L166 118L161 117L159 116L157 118L156 118L155 117L150 119L147 119L145 118L141 117L138 117L135 114L133 113L132 112L132 111L131 109L130 108L129 104z',
    },
    {
      name: 'Nikšić',
      path:
        'M17 133L18 132L20 132L19 130L18 130L19 130L18 130L19 130L19 129L18 129L19 129L19 127L20 127L20 126L22 126L22 124L21 123L24 122L23 121L23 119L21 115L21 108L22 106L22 104L24 102L24 101L25 101L25 98L27 97L27 96L35 96L38 97L39 97L41 96L42 96L42 95L44 95L43 94L44 94L43 94L43 84L45 84L48 87L50 86L51 85L53 87L54 91L58 101L59 102L61 103L61 105L68 110L71 112L82 112L84 113L85 114L86 114L89 117L92 117L94 119L98 122L103 125L104 125L108 130L109 128L111 127L113 127L117 129L118 131L114 135L113 137L113 139L112 143L114 144L114 147L112 149L113 151L113 152L112 153L113 154L116 154L116 155L114 156L112 156L109 154L108 154L106 156L101 156L98 155L96 155L94 156L93 156L92 155L87 160L87 163L90 166L90 168L88 169L86 169L85 168L81 169L79 171L77 169L77 167L75 164L73 163L71 161L70 158L68 157L60 157L56 155L55 155L54 158L52 157L52 160L50 161L52 165L52 166L48 169L46 167L45 167L42 170L38 170L36 172L36 174L29 177L30 176L29 174L29 173L28 172L27 172L27 170L25 169L27 167L27 166L28 166L28 165L33 165L32 163L32 162L31 162L31 161L30 161L30 160L29 160L29 156L30 156L30 155L31 154L31 152L32 152L32 151L27 151L26 150L25 150L25 149L24 148L23 148L23 147L21 145L19 145L20 142L20 141L19 141L19 139L18 139L18 138L19 138L19 135L18 135L18 134z',
    },
    {
      name: 'Petnjica',
      path:
        'M200 115L206 109L209 109L213 106L214 107L215 106L223 101L223 102L225 104L225 103L226 103L226 104L227 104L227 106L228 106L228 107L223 122L220 126L219 125L216 124L215 123L206 117z',
    },
    {
      name: 'Plav',
      path:
        'M194 167L194 166L195 165L195 164L196 164L197 163L199 159L200 158L201 158L201 157L203 156L205 156L205 155L206 155L207 154L208 154L209 155L209 154L211 154L212 153L215 154L216 154L216 153L220 153L220 155L222 155L222 156L223 156L223 157L224 157L225 158L227 158L227 160L228 161L230 162L230 164L229 164L228 165L228 167L227 167L226 168L226 170L225 170L225 171L226 171L226 173L227 174L227 178L226 178L226 180L224 180L224 179L223 179L223 180L222 181L221 181L221 182L218 182L218 186L217 186L217 187L216 188L214 188L214 187L213 187L213 188L210 188L210 187L209 187L209 186L208 186L208 187L207 187L206 188L204 188L203 185L203 179L202 177L202 176L205 176L205 174L204 174L204 173L203 173L202 172L200 171L198 171z',
    },
    {
      name: 'Pljevlja',
      path:
        'M76 13L77 13L78 12L78 11L78 12L79 11L80 11L80 10L81 10L82 9L85 9L85 5L86 5L86 4L87 3L88 3L88 5L89 5L89 6L90 6L93 9L93 10L92 10L92 11L93 11L93 13L95 13L95 12L97 12L98 11L100 11L100 10L104 10L104 9L105 9L105 8L107 8L107 7L108 7L108 8L109 8L110 7L110 8L112 8L113 7L113 8L116 8L116 11L117 11L117 12L116 12L116 17L117 18L118 18L119 19L119 20L120 20L121 21L122 21L122 22L123 22L124 23L125 23L125 24L125 23L126 24L129 24L131 26L131 27L132 29L137 29L137 30L138 30L137 30L138 31L139 31L139 32L140 32L140 33L145 33L145 37L147 39L146 39L147 40L147 41L149 43L149 44L150 44L152 45L153 45L154 46L155 46L156 47L156 48L157 49L156 49L156 51L155 52L155 54L156 54L156 55L157 55L157 58L158 59L156 63L154 63L152 69L152 70L153 71L152 72L150 73L147 78L147 80L144 83L141 83L142 85L141 86L140 88L141 89L140 90L137 90L134 89L134 87L133 86L133 85L132 84L127 80L124 80L124 77L122 75L121 75L120 73L119 72L117 71L116 71L112 67L110 66L108 64L103 64L101 66L100 65L94 66L92 65L92 64L91 62L90 62L87 60L87 57L88 58L90 56L90 55L91 54L92 52L92 51L93 51L93 50L95 50L98 49L97 49L97 48L98 48L98 46L97 44L97 43L95 41L95 40L94 39L93 39L93 38L92 37L93 37L92 36L92 35L93 35L93 32L92 32L90 30L90 26L89 26L89 24L88 24L89 23L88 23L88 24L88 23L88 24L87 24L87 23L86 23L86 22L85 22L86 22L85 22L84 21L84 22L82 22L82 15L81 15L81 13L79 13L79 15L77 15L77 14L76 13z',
    },
    {
      name: 'Plužine',
      path:
        'M42 76L42 75L43 74L43 70L44 70L44 66L46 65L47 63L51 61L50 61L50 60L49 60L49 59L47 57L53 52L58 50L60 48L62 48L64 47L64 46L65 47L66 46L66 45L68 45L68 42L67 42L67 40L70 40L70 39L75 39L75 38L76 38L76 39L77 39L77 40L78 40L78 41L80 43L80 44L81 44L81 43L82 43L82 46L81 47L81 51L83 51L84 52L85 52L85 53L86 54L85 55L85 56L84 58L84 63L85 69L85 73L88 78L90 80L90 82L89 83L86 81L84 83L84 85L82 92L80 95L79 98L78 99L79 101L80 102L84 104L86 104L90 107L89 109L88 110L86 114L85 114L84 113L82 112L71 112L61 105L61 103L59 102L58 101L54 91L53 87L51 85L50 86L48 87L45 84L43 84L43 83L42 81L43 81L42 80L42 77z',
    },
    {
      name: 'Podgorica',
      path:
        'M130 236L130 236zM128 235L129 235zM103 228L103 228zM105 232L106 232L105 232zM130 235L130 234L131 234L131 235L130 235zM86 194L88 192L90 193L90 195L101 195L109 193L110 194L113 194L114 193L116 187L115 187L114 186L115 180L117 178L119 174L123 163L124 162L128 162L132 161L134 162L136 164L138 162L140 162L140 161L139 160L139 158L141 159L142 159L145 157L144 154L147 154L148 153L150 154L159 154L160 155L162 154L166 157L166 159L168 158L172 158L172 164L173 165L173 167L172 167L172 168L171 170L168 173L168 174L167 174L167 175L166 176L166 181L167 181L166 182L167 182L166 182L166 183L165 183L165 184L164 185L164 184L163 183L163 181L162 180L162 181L159 184L159 186L158 186L158 187L156 187L155 190L154 191L153 193L149 195L148 194L144 194L144 193L142 193L141 194L141 199L140 202L137 203L136 203L134 202L131 202L127 205L127 210L125 212L125 218L127 220L127 223L126 226L127 228L128 229L129 229L129 232L129 231L129 232L130 232L130 230L130 234L129 234L129 233L127 233L128 233L128 234L125 234L125 233L123 233L123 232L122 232L121 231L120 231L120 230L120 231L118 231L118 230L117 231L117 230L114 230L114 231L113 231L113 230L112 230L112 231L111 231L111 233L110 233L110 232L109 231L108 231L108 230L107 231L106 231L106 232L106 231L106 232L105 232L105 231L106 231L105 230L106 230L106 231L106 230L105 230L105 229L105 230L103 230L103 229L102 230L103 230L103 228L105 228L104 228L105 228L104 228L105 228L107 226L105 227L104 228L103 228L104 228L104 227L102 227L109 223L108 215L98 209L98 207L97 204L95 202L94 203L93 203L92 202L92 199L90 198L89 196z',
    },
    {
      name: 'Rožaje',
      path:
        'M217 132L219 129L217 128L220 126L223 122L228 107L230 107L231 108L232 108L234 106L234 105L235 105L235 104L236 104L235 105L236 105L235 105L236 106L237 106L237 107L238 107L239 108L241 109L243 109L244 110L245 110L245 111L246 111L246 112L248 112L249 113L250 113L251 114L252 114L252 113L252 114L253 114L253 115L254 115L255 116L256 116L256 115L256 116L257 117L257 116L257 117L259 117L259 118L261 118L261 121L260 122L261 123L261 124L260 124L260 126L261 127L261 131L262 131L258 131L257 132L257 131L254 131L254 133L253 133L253 132L252 132L251 133L252 133L251 133L251 134L250 134L250 135L249 134L249 135L249 134L249 135L248 135L249 135L249 136L250 136L250 141L249 142L249 143L247 145L244 145L244 146L243 146L243 147L243 146L241 146L241 147L240 147L240 145L238 145L238 144L237 144L236 143L235 144L235 145L234 145L234 144L231 144L231 143L230 143L229 142L229 141L227 141L227 142L226 141L223 141L218 136L218 135L220 133L219 133z',
    },
    {
      name: 'Tuzi',
      path:
        'M130 236L130 236zM128 235L129 235zM103 228L103 228zM105 232L106 232L105 232zM130 235L130 234L131 234L131 235L130 235zM128 229L127 228L126 226L127 223L127 220L125 218L125 212L127 210L127 205L131 202L134 202L136 203L137 203L140 202L141 199L141 194L142 193L144 193L144 194L148 194L149 195L153 193L154 191L155 190L156 187L158 187L158 186L159 186L159 184L162 181L162 180L163 181L163 183L164 184L164 185L160 187L160 190L159 190L159 191L158 192L158 193L157 194L157 196L156 196L156 197L155 198L154 198L154 200L152 200L152 201L151 202L151 203L150 205L149 206L149 207L148 208L148 209L146 209L146 210L143 210L143 212L142 212L142 215L141 215L140 216L141 218L141 220L140 220L140 221L139 221L138 222L138 223L137 223L137 224L136 223L137 223L136 223L136 224L135 225L135 226L136 227L135 228L133 228L133 227L132 227L132 229L131 229L131 230L130 230L129 229L129 228L129 229z',
    },
    {
      name: 'Tivat',
      path:
        'M46 205L46 205zM49 206L49 206zM46 205L48 205L49 206L48 206L48 205L48 206L48 205L46 205zM38 206L39 205L39 204L40 204L40 205L42 205L43 206L43 205L43 206L47 206L47 207L48 207L49 208L50 208L49 208L50 207L49 207L50 207L50 206L49 206L50 206L50 203L49 203L49 202L48 202L48 201L47 202L47 201L47 202L48 201L47 201L48 201L47 201L48 201L47 201L48 201L47 200L48 200L47 200L47 198L46 198L46 196L47 196L47 194L48 194L47 194L48 194L48 195L49 196L50 196L50 197L51 197L51 198L54 202L53 203L53 206L54 207L53 207L54 208L53 208L53 210L51 210L48 212L47 212L47 210L45 210L45 209L44 209L44 210L42 210L43 210L43 211L42 211L43 212L42 213L43 213L42 213L42 214L42 212L41 211L41 207L40 207L40 206L39 206z',
    },
    {
      name: 'Ulcinj',
      path:
        'M106 278L107 278L110 279L118 283L119 283L121 280L120 274L115 271L118 270L118 265L125 269L126 269L130 267L136 267L136 269L135 269L135 276L136 276L136 281L137 282L137 283L133 283L133 284L132 284L133 285L132 286L132 287L133 287L133 288L135 289L135 291L133 291L132 292L131 292L131 293L132 293L132 294L134 294L135 295L135 296L136 296L136 297L135 298L135 299L136 300L135 300L135 303L135 302L135 303L134 303L134 302L133 302L133 301L131 301L131 300L130 299L130 298L129 298L127 296L126 296L125 295L123 294L122 294L121 293L118 292L117 292L118 292L118 293L117 293L117 292L116 292L116 291L115 291L115 290L115 291L115 290L114 291L114 290L113 290L113 289L111 289L111 288L110 288L110 287L109 288L109 287L108 287L108 286L107 286L107 285L106 285L109 285L108 285L108 284L106 282L106 281L105 281L106 281L105 281L105 280L107 280L107 279L106 279L106 278zM105 278L105 278z',
    },
    {
      name: 'Šavnik',
      path:
        'M78 99L79 98L80 95L82 92L84 85L84 83L86 81L89 83L90 82L92 84L91 85L91 87L92 87L94 89L97 89L99 88L103 88L103 92L107 94L108 94L111 92L111 91L113 91L118 95L118 96L116 97L116 98L117 98L118 99L123 99L127 103L129 104L130 108L131 109L132 111L132 112L133 113L133 116L132 117L131 117L130 116L128 116L126 118L122 118L121 117L118 117L116 118L114 118L115 120L114 121L113 123L113 127L111 127L109 128L108 130L104 125L103 125L98 122L94 119L92 117L89 117L86 114L88 110L89 109L90 107L86 104L84 104L80 102L79 101z',
    },
    {
      name: 'Žabljak',
      path:
        'M84 63L84 58L85 56L86 56L87 57L87 60L90 62L91 62L92 64L92 65L94 66L99 65L100 65L101 66L103 64L108 64L110 66L112 67L116 71L117 71L119 72L120 73L121 75L122 75L124 77L124 80L127 80L133 85L133 86L134 87L134 90L135 91L135 94L133 95L132 96L128 97L127 103L123 99L118 99L117 98L116 98L116 97L118 96L118 95L113 91L111 91L111 92L108 94L107 94L103 92L103 88L99 88L97 89L94 89L92 87L91 87L91 85L92 84L90 82L90 80L88 78L85 73L85 69z',
    },
  ];

  mouseEnter(e, city) {
    this.svg.nativeElement.querySelector('.svg-group-cities').appendChild(e.target);
    this.tooltip.nativeElement.style.visibility = 'visible';
    this.activeCity = city;
  }

  mouseLeave(e) {
    this.tooltip.nativeElement.style.visibility = 'hidden';
  }

  constructor(private rd: Renderer2) {}

  ngOnInit(): void {
    this.cities.map((city) => {
      city.path = this.paths.filter((item) => item.name === city.name)[0].path;
      return city;
    });
  }

  setFill(city) {
    let opacity = '0%';
    const count = city.count;

    if (count > 0 && count < 4) {
      opacity = '0.1';
    } else if (count >= 0 && count < 10) {
      opacity = '0.2';
    } else if (count >= 10 && count < 50) {
      opacity = '0.3';
    } else if (count >= 50 && count < 100) {
      opacity = '0.4';
    } else if (count >= 100 && count < 500) {
      opacity = '0.5';
    } else if (count >= 500 && count < 1000) {
      opacity = '0.6';
    } else if (count >= 1000 && count < 5000) {
      opacity = '0.7';
    } else if (count >= 5000 && count < 10000) {
      opacity = '0.8';
    } else if (count >= 10000 && count < 50000) {
      opacity = '0.9';
    } else if (count >= 50000) {
      opacity = '1';
    }
    return opacity;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    const x = e.clientX;
    const y = e.clientY;

    if (window.innerWidth < x + 150) {
      this.tooltip.nativeElement.style.top = y + 'px';
      this.tooltip.nativeElement.style.left = x - 100 + 'px';
    } else {
      this.tooltip.nativeElement.style.top = y + 'px';
      this.tooltip.nativeElement.style.left = x + 15 + 'px';
    }
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(e) {
    this.tooltip.nativeElement.style.visibility = 'hidden';
  }

  ngAfterViewInit() {}
}
