import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendFormDataService } from '../services/sendFormData.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
})
export class ContactComponent implements OnInit, OnDestroy {
  contactForm: FormGroup;
  acceptedPolicy: boolean = false;

  recaptchaStr = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private sendFormData: SendFormDataService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      question: new FormControl(null, Validators.required),
    });
  }

  public validCaptcha(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.onSubmit();
    }
  }

  onFormSubmit(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }

  onSubmit() {
    // console.log(this.contactForm);
    if (!this.contactForm) {
      return;
    }

    if (this.contactForm.valid) {
      this.loadingService.showLoadingBar();
      this.sendFormData.submitContactForm(this.contactForm.value).subscribe(
        (response: any) => {
          console.log(response);
          this.loadingService.hideLoadingBar();
          if ((response.status = 'Succes')) {
            this.toastr.success('You have successfully completed the contact form!', 'Succes');
          }
          this.contactForm.reset();
          this.ref.close();
        },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        }
      );
    } else {
      this.toastr.error(
        'Upit nije validan! Molimo unesite sva Inquiry is invalid! Please enter all required fields!',
        'Error'
      );
    }
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
