import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CookieModule, CookieService } from '@gorniv/ngx-universal';
import { UniversalStorage } from './shared/storage/universal.storage';

import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RECAPTCHA_LANGUAGE,
} from 'ng-recaptcha';

import { ToastrModule } from 'ngx-toastr';
import { AngularSvgIconModule } from './shared/angular-svg-icon/angular-svg-icon.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'womenviolence' }),
    TransferHttpCacheModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    // NoopAnimationsModule,
    LayoutModule,
    AppRoutes,
    CookieModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    CookieService,
    UniversalStorage,
    { provide: LOCALE_ID, useValue: 'sr-Latn-ME' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Le30-QZAAAAAEoP07wTvWvOyguQ12ivGK15jvtU' } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
