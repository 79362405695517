import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/browser/environment';

@Injectable({ providedIn: 'root' })
export class CalendarDialogService {
    events = new Subject<any>();

    constructor(private http: HttpClient) { }

    getEvents(fromDate: string, toDate: string) {
        return this.http.get(`${environment.api}/calendar/client`, {
            params: new HttpParams()
                .set('from', fromDate)
                .set('to', toDate)
        })
    }

    getEvent(id: string) {
        return this.http.get(`${environment.api}/calendar/client/${id}`)
    }

}