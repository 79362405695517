import { Injectable, NgZone } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  timer: any;
  loadingtimer: any;

  constructor(private _router: Router, private _ngZone: NgZone) {
    this._init();
  }

  showLoadingBar() {
    clearTimeout(this.loadingtimer);
    document.getElementById('loading-bar').classList.add('visible');
  }

  hideLoadingBar() {
    this._ngZone.runOutsideAngular(() => {
      this.loadingtimer = setTimeout(function () {
        document.getElementById('loading-bar').classList.remove('visible');
      }, 1500);
    });
  }

  showLoadingBarSafe() {
    document.getElementById('loading-bar').classList.add('visible-safe');
  }

  hideLoadingBarSafe() {
    document.getElementById('loading-bar').classList.remove('visible-safe');
  }

  private _init(): void {
    // Subscribe to the router events to show/hide the loading bar
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.showLoadingBar();
    });

    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe(() => {
        this.hideLoadingBar();
      });
  }

  showBigLoading() {
    clearTimeout(this.timer);
    document.body.classList.remove('fade-load');
    document.getElementById('loading-overlay').classList.remove('hidden');
  }

  hideBigLoading(delay) {
    this._ngZone.runOutsideAngular(() => {
      this.timer = setTimeout(function () {
        document.body.classList.add('fade-load');
        setTimeout(function () {
          document.getElementById('loading-overlay').classList.add('hidden');
        }, 500);
      }, delay);
    });
  }
}
