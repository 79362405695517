import { NgModule } from '@angular/core';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' | ',
    applicationName: 'Gender map',
    defaults: {
      title: 'Gender map',
      description:
        'The gender map of Montenegro represents an innovative approach in presenting the socio-economic picture of society from a gender perspective.',
      'og:site_name': 'Gender map',
      'og:title': 'Gender map',
      'og:image': 'https://www.gendermap.me/assets/rodnamapa.jpg',
      'og:type': 'website',
      'og:locale': 'en_US',
      'og:description':
        'The gender map of Montenegro represents an innovative approach in presenting the socio-economic picture of society from a gender perspective.',
      'og:image:width': '1920',
      'og:image:height': '1217',
    },
  });
}

@NgModule({
  imports: [
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
    }),
  ],
})
export class SharedMetaModule {}
