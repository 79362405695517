import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NextTransition, PrevTransition, TestTransition } from './test-transition';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  animations: [TestTransition, PrevTransition, NextTransition],
})
export class TestComponent implements OnChanges {
  /**
   *
   *
   *
   *  questions:
   * {
   *  question: string;
   *  answers: { value: string; correct?: boolean; checked?:boolean}[];
   *  }[];
   *
   * @param {Object[]} questions
   * @param {string} questions[].question - question you want to be passed
   * @param {Object[]} questions[].answers - array of answers that will be displayed as radio buttons
   * @param {string} answers[].value - answer text
   * @param {boolean} answers[].correct - corect answer (can only be one for one question)
   * @param {boolean} answers[].checked - answer is already checked (can only be one for one question)
   *
   */
  @Input() questions: {
    question: string;
    answers: { value: string; correct?: boolean; checked?: boolean }[];
  }[];

  /**
   *
   *  answerType:
   * { from: number; to: number; answer: string; image }[];
   *
   * @param {Object[]} answerType
   * @param {number} answerType[].from - Example from 0
   * @param {number} answerType[].to - Example to 10
   * @param {string} answerType[].answer - Answer to be displayed at the end of the test
   * @param {string} answerType[].image - Image that is later emitted by svgImage
   *
   */
  @Input() answerType: { from: number; to: number; answer: string; image: string }[];

  /**
   *  OPTIONAL!
   *
   * intro:  {title: string; description: string };
   *
   * @param {Object} intro - { title: string; description: string };
   * @param {string} intro.title - Title of the test
   * @param {string} intro.description - test description
   *
   */
  @Input() intro: { title: string; description: string };

  /**
   * optional value that will save in local storage under the name provided
   *
   * @param {string} saveName
   *
   */
  @Input() saveName: string;

  /**
   * optional value that contains the default image to be returned (used with answerType[].image)
   *
   * @param {string} svgDefault
   *
   */
  @Input() svgDefault: string;

  /**
   * Emits the answer from AnswerType
   *
   * @param {Object} answer
   *  @param {number} answer.from - Example from 0
   * @param {number} answer.to - Example to 10
   * @param {string} answer.answer - Answer to be displayed at the end of the test
   * @param {string} answer.image - Image that is later emitted by svgImage
   *
   */
  @Output() answer = new EventEmitter<{ image: string }>();

  currentQuestion: any;
  sliderWidth = 0;
  finalResult: any;
  answered: boolean;
  currentIndex = 0;
  state: 'intro' | 'question' | 'final-result';
  isMobile = false;
  //TRANSITIONS
  triggerNext: boolean = true;
  triggerPrev: boolean = true;
  constructor(private cdr: ChangeDetectorRef, private deviceService: DeviceDetectorService) {}

  ngOnChanges(): void {
    this.questions = JSON.parse(JSON.stringify(this.questions));
    this.state = 'intro';
    this.checkLocalStorage();
    if (!this.intro) {
      this.onStartTest();
    }

    this.isMobile = this.deviceService.isMobile();
  }

  getQuestion(question_id) {
    this.currentIndex = question_id;
    this.answered = !!this.questions[question_id]?.answers.find((answer) => answer.checked);
    return this.questions[question_id];
  }

  onNext(index, event) {
    if (event.fromState !== 'void') {
      if (index < this.questions.length) {
        this.currentQuestion = this.getQuestion(index);
        this.cdr.detectChanges();
      }
    }
  }

  transitionNext(currentQuestion) {
    this.currentIndex = this.questions.indexOf(currentQuestion) + 1;
    if (this.currentIndex < this.questions.length) {
      this.calcSliderWidth(this.currentIndex);
      this.triggerNext = !this.triggerNext;
    } else {
      this.onFinalResult(this.currentIndex);
      this.state = 'final-result';
    }
  }

  transitionPrev(currentQuestion) {
    this.currentIndex = this.questions.indexOf(currentQuestion) - 1;
    if (this.currentIndex >= 0) {
      if (this.state === 'final-result') {
        this.currentIndex = this.questions.indexOf(currentQuestion);
        this.calcSliderWidth(this.currentIndex);
        this.state = 'question';
        this.outputNewImage(this.svgDefault);
      } else {
        this.calcSliderWidth(this.currentIndex);
      }
      this.triggerPrev = !this.triggerPrev;
    }
  }

  onPrev(index, event) {
    if (event.fromState !== 'void') {
      if (index >= 0) {
        this.currentQuestion = this.getQuestion(index);
        this.cdr.detectChanges();
      }
    }
  }

  calcSliderWidth(index) {
    const percentage = (index / this.questions.length) * 100;
    this.sliderWidth = percentage;
  }

  onSelect(currentQuestion, answer) {
    currentQuestion.answers.map((answer) => {
      if (answer.checked) {
        delete answer.checked;
      }
      return answer;
    });
    answer['checked'] = true;
    this.answered = true;
    if (this.saveName) {
      localStorage.setItem(this.saveName, JSON.stringify(this.questions));
    }
  }

  onStartTest() {
    this.state = 'question';
    this.currentQuestion = this.getQuestion(0);
  }

  onFinalResult(index) {
    this.currentIndex = this.questions.length - 1;
    const correct = this.questions
      .map((question) => question.answers.find((answer) => answer.correct && answer.checked))
      .filter((value) => value).length;
    this.finalResult = this.answerType.find((item) => correct >= item.from && correct <= item.to);
    this.outputNewImage(this.finalResult.image);
    this.calcSliderWidth(index);
  }

  checkLocalStorage() {
    if (localStorage.getItem(this.saveName)) {
      this.questions = JSON.parse(localStorage.getItem(this.saveName));
      const answered = this.questions
        .map((question) => !!question.answers.find((answer) => answer.checked))
        .filter((value) => value).length;
      if (answered === this.questions.length) {
        this.onFinalResult(answered);
        this.calcSliderWidth(answered);
        this.state = 'final-result';
      } else {
        this.calcSliderWidth(answered - 1);
        this.state = 'question';
        this.outputNewImage(this.svgDefault);
      }
      this.currentQuestion = this.getQuestion(answered - 1);
    } else {
      this.outputNewImage(this.svgDefault);
    }
  }

  outputNewImage(data: string) {
    this.answer.emit({ image: data });
  }

  repeatTest() {
    this.currentIndex = 0;
    this.outputNewImage(this.svgDefault);
    this.state = 'intro';
    this.calcSliderWidth(this.currentIndex);
    this.questions = this.questions.map((question) => {
      question.answers.map((answer) => {
        if (answer.checked) {
          delete answer.checked;
        }
        return answer;
      });
      return question;
    });
    this.finalResult = null;
    localStorage.removeItem(this.saveName);
  }
}
