import { Component, ViewEncapsulation } from '@angular/core';
import { routerTransition } from 'src/app/router-transition';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [routerTransition],
})
export class ContentComponent {
  constructor() {}
}
