import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { CalendarComponent } from './calendar-dialog.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CalendarInfoComponent } from './calendar-info/calendar-info.component';


FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [CalendarComponent, CalendarInfoComponent],
  imports: [
    BrowserModule,
    FullCalendarModule,
    DynamicDialogModule
  ],
  exports: [CalendarComponent],
  entryComponents: [CalendarComponent, CalendarInfoComponent],
})
export class CalendarModule { }
