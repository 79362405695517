<div class="p-dialog-header">
  <h4 class="p-dialog-header__title documents-header">
    {{title}}
  </h4>
  <div class="p-dialog-header__close">
    <button class="btn-close" (click)="onClose()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9095 1.19727L1.05591 17.0509M16.9095 17.0509L1.05591 1.19727" stroke="black"
          stroke-width="1.50987" />
      </svg>
    </button>
  </div>
</div>
<div class="p-dialog-container">
  <div class="documents-wrapper">
    <a *ngFor="let document of documents" href="{{document.url}}" target="_blank">
      <div class="document-wrap">
        <span class="document-icon">
          <svg viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8324 23.7216C10.8324 22.4749 9.96675 21.7309 8.43911 21.7309C7.81595 21.7309 7.39376 21.791 7.17236 21.8512V25.8544C7.4341 25.9138 7.75585 25.9341 8.19712 25.9341C9.82698 25.9341 10.8324 25.1097 10.8324 23.7216Z"
              fill="#172E56" />
            <path
              d="M20.2874 21.7711C19.6028 21.7711 19.1613 21.8313 18.8989 21.8917V30.7611C19.1608 30.8224 19.5833 30.8224 19.9655 30.8224C22.7407 30.8418 24.5509 29.314 24.5509 26.0756C24.5712 23.2588 22.9216 21.7711 20.2874 21.7711Z"
              fill="#172E56" />
            <path
              d="M38.4052 16.5598H37.2935V11.196C37.2935 11.1625 37.2882 11.1288 37.2837 11.0949C37.282 10.8819 37.2142 10.6727 37.0684 10.507L28.1423 0.310488C28.1396 0.307882 28.137 0.306958 28.1352 0.3041C28.082 0.244675 28.02 0.195253 27.9544 0.15247C27.935 0.139442 27.9154 0.128515 27.8951 0.117252C27.8382 0.0862373 27.778 0.0605174 27.7159 0.0419419C27.6991 0.037235 27.684 0.0303427 27.6672 0.025972C27.5998 0.0100022 27.5298 0 27.4588 0H5.52187C4.52014 0 3.70635 0.814715 3.70635 1.81561V16.5594H2.59493C1.16193 16.5594 0 17.7208 0 19.1543V32.6485C0 34.0809 1.16193 35.2431 2.59493 35.2431H3.70644V44.4803C3.70644 45.4811 4.52023 46.2959 5.52196 46.2959H35.478C36.4789 46.2959 37.2936 45.4812 37.2936 44.4803V35.2431H38.4052C39.8378 35.2431 41 34.0809 41 32.6485V19.1548C40.9999 17.7213 39.8377 16.5598 38.4052 16.5598ZM5.52187 1.81594H26.5511V11.1048C26.5511 11.6063 26.958 12.0125 27.4588 12.0125H35.478V16.5604H5.52187V1.81594ZM27.809 25.974C27.809 28.5692 26.864 30.3585 25.5547 31.4653C24.1283 32.6519 21.9556 33.2148 19.3016 33.2148C17.713 33.2148 16.587 33.1142 15.8218 33.014V19.6988C16.948 19.5182 18.4171 19.4176 19.9659 19.4176C22.5388 19.4176 24.2086 19.8805 25.5166 20.8655C26.9238 21.9115 27.809 23.5805 27.809 25.974ZM4.13502 33.0743V19.6988C5.07984 19.5388 6.40795 19.4176 8.27844 19.4176C10.1688 19.4176 11.5167 19.7797 12.4214 20.5042C13.2865 21.1876 13.8684 22.3142 13.8684 23.6417C13.8684 24.9696 13.4271 26.0958 12.622 26.8596C11.5762 27.8454 10.0277 28.2882 8.21708 28.2882C7.81548 28.2882 7.45221 28.2677 7.1719 28.2274V33.0747H4.13502V33.0743ZM35.478 43.9888H5.52187V35.2431H35.478V43.9888H35.478ZM38.1086 22.0323H32.8983V25.1297H37.766V27.6238H32.8983V33.0744H29.8226V19.5182H38.1086V22.0323Z"
              fill="#172E56" />
          </svg>
        </span>
        <p class="document-title">{{document.name}}</p>
      </div>
    </a>

  </div>
</div>
