import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';
import { resolve } from 'dns';
import { environment } from 'src/environments/environment';
import { TransferHttpService } from '@gorniv/ngx-universal';

@Component({
  selector: 'app-story-dialog',
  templateUrl: './story-dialog.component.html',
})
export class StoryComponent implements OnInit, OnDestroy {
  victimStory: any;
  showSpinner = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private http: TransferHttpService,
    @Inject('ORIGIN_URL') public baseUrl: string
  ) {}

  ngOnInit(): void {
    this.http.get(`${environment.api}/story/client/${this.config.data.story._id}`).subscribe(
      (data: any) => {
        this.victimStory = data.data.story;
        this.showSpinner = false;
        if (!this.victimStory) {
          this.ref.close();
        }
      },
      () => {
        this.ref.close();
      }
    );
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
