import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendFormDataService } from '../services/sendFormData.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-send-story-dialog',
  templateUrl: './send-story-dialog.component.html',
})
export class SendStoryComponent implements OnInit, OnDestroy {
  type;
  storyForm: FormGroup;
  acceptedPolicy = false;
  category: string;

  recaptchaStr = '';

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['underline', 'image'],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['link'],
      ],
    },
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private sendFormData: SendFormDataService,
    private toastr: ToastrService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.type = this.config.data.type;
    console.log(this.type);
  }

  ngOnInit(): void {
    this.storyForm = new FormGroup({
      title: new FormControl(null, Validators.required),
      content: new FormControl(null, Validators.required),
    });

    if (this.router.url.includes('gender-based-violence')) {
      this.category = 'violence';
    } else if (this.router.url.includes('politics')) {
      this.category = 'politics';
    } else if (this.router.url.includes('economy')) {
      this.category = 'entrepreneurship';
    }
  }

  public validCaptcha(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      console.log('123b');
      this.onSubmit();
    }
  }

  onFormSubmit(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }

  onSubmit() {
    if (!this.storyForm) {
      return;
    }

    if (this.storyForm.valid) {
      this.loadingService.showLoadingBar();
      this.sendFormData.submitStoryForm(this.storyForm.value, this.category).subscribe(
        (res: any) => {
          if ((res.status = 'Succes')) {
            console.log(res);
            this.loadingService.hideLoadingBar();
            this.toastr.success('Vaša prča je uspješno poslata!', 'Uspješno');

            this.storyForm.reset();
            this.ref.close();
          }
        },
        (res) => {
          this.toastr.error(res.error.message, 'Greška');
        }
      );
    } else {
      this.toastr.error('Molimo unesite sva obavezna polja!', 'Greška');
    }
  }

  onClose() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
  }
}
