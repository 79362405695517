import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FusePipesModule } from './pipes/pipes.module';
import { SvgMontenegroComponent } from './svg-montenegro/svg-montenegro.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { NgxMasonryModule } from './ngx-masonry-master/ngx-masonry.module';
import { SendStoryComponent } from './send-story-dialog/send-story-dialog.component';
import { StoryComponent } from './story-dialog/story-dialog.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReportViolenceComponent } from './report-violence-dialog/report-violence-dialog.component';
import { TestModule } from './test/test.module';
import { QuillModule } from 'ngx-quill';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { CityInfoComponent } from './city-info-dialog/city-info-dialog.component';
import { AngularSvgIconModule } from './angular-svg-icon/angular-svg-icon.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DocumentsDialogComponent } from './documents-dialog/documents-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { EBookDialogComponent } from './e-book-dialog/e-book-dialogcomponent';
import { PoliticsNetworkDialogComponent } from './politics-network-dialog/politics-network-dialog.component';

@NgModule({
  declarations: [
    SvgMontenegroComponent,
    ReportViolenceComponent,
    SendStoryComponent,
    StoryComponent,
    CityInfoComponent,
    DocumentsDialogComponent,
    EBookDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FusePipesModule,
    TooltipModule,
    DynamicDialogModule,
    NgxMasonryModule,
    NgxChartsModule,
    TestModule,
    QuillModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    AngularSvgIconModule,
    InfiniteScrollModule,
    LightboxModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FusePipesModule,
    TooltipModule,
    NgxMasonryModule,
    DynamicDialogModule,
    SvgMontenegroComponent,
    NgxChartsModule,
    TestModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    AngularSvgIconModule,
    InfiniteScrollModule,
    LightboxModule,
  ],
  entryComponents: [
    SendStoryComponent,
    ReportViolenceComponent,
    StoryComponent,
    CityInfoComponent,
    DocumentsDialogComponent,
    PoliticsNetworkDialogComponent,
  ],
})
export class RoutesSharedModule {}
