import { NgModule } from '@angular/core';
import { HeaderComponent, IfChangesDirective } from './header.component';
import { RouterModule } from '@angular/router';
import { RoutesSharedModule } from 'src/app/shared/routes-shared.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { GetTrainedComponent } from 'src/app/shared/get-trained-dialog/get-trained-dialog.component';
import { ContactComponent } from 'src/app/shared/contact-dialog/contact-dialog.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule } from 'src/app/shared/calendar-dialog/calendar-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [HeaderComponent, IfChangesDirective, GetTrainedComponent, ContactComponent],
  imports: [
    RouterModule,
    RoutesSharedModule,
    DynamicDialogModule,
    TooltipModule,
    SlickCarouselModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
