import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        TruncatePipe
    ],
    imports     : [],
    exports     : [
        SafeHtmlPipe,
        TruncatePipe
    ]
})
export class FusePipesModule
{
}
